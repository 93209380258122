.tq-gutter > .tq-flex-container {
  margin: 0 -8px;
  min-width: calc( 100% + 16px );
}
.tq-gutter > .tq-flex-container.tq-flex-container-grid {
  margin: 0;
  min-width: 100%;
}
.tq-grid-a .tq-flex-container {
  margin: 0 -8px;
  min-width: calc( 100% + 16px );
}
.tq-grid-a .tq-flex-container.tq-flex-container-grid {
  margin: 0;
  min-width: 100%;
}
.tq-gutter-all > .tq-flex-container {
  margin: 0 -8px;
  min-width: calc( 100% + 16px );
}
.tq-gutter-all > .tq-flex-container.tq-flex-container-grid {
  margin: 0;
  min-width: 100%;
}
.tq-flex-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.tq-flex-container .tq-flex-item {
  flex: 1;
  justify-content: center;
  margin: 8px;
  padding: 0px;
}
.tq-flex-container.tq-flex-container-grid .tq-flex-item {
  margin: 0px;
}
.tq-flex-container {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.tq-flex-container.tq-card-centered {
  justify-content: center;
}
.tq-flex-container.tq-flex-item-l-10.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n10.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-10 .tq-flex-item,
.tq-flex-container.n10 .tq-flex-item {
  flex: none;
  width: calc(10% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-9.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n9.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-9 .tq-flex-item,
.tq-flex-container.n9 .tq-flex-item {
  flex: none;
  width: calc(11.11111111111111% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-8.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n8.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-8 .tq-flex-item,
.tq-flex-container.n8 .tq-flex-item {
  flex: none;
  width: calc(12.5% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-7.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n7.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-7 .tq-flex-item,
.tq-flex-container.n7 .tq-flex-item {
  flex: none;
  width: calc(14.285714285714286% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-6.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n6.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-6 .tq-flex-item,
.tq-flex-container.n6 .tq-flex-item {
  flex: none;
  width: calc(16.666666666666668% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-5.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n5.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-5 .tq-flex-item,
.tq-flex-container.n5 .tq-flex-item {
  flex: none;
  width: calc(20% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-4.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n4.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-4 .tq-flex-item,
.tq-flex-container.n4 .tq-flex-item {
  flex: none;
  width: calc(25% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-3.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n3.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-3 .tq-flex-item,
.tq-flex-container.n3 .tq-flex-item {
  flex: none;
  width: calc(33.333333333333336% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-2.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n2.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-2 .tq-flex-item,
.tq-flex-container.n2 .tq-flex-item {
  flex: none;
  width: calc(50% - 16px);
  box-sizing: border-box;
}
.tq-flex-container.tq-flex-item-l-1.tq-flex-item-stretch .tq-flex-item,
.tq-flex-container.n1.tq-flex-item-stretch .tq-flex-item {
  flex-grow: 1;
}
.tq-flex-container.tq-flex-item-l-1 .tq-flex-item,
.tq-flex-container.n1 .tq-flex-item {
  flex: none;
  width: calc(100% - 16px);
  box-sizing: border-box;
}
@media (max-width: 1336px) {
  .tq-flex-container.tq-flex-item-m-10.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n10.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-10 .tq-flex-item,
  .tq-flex-container.n10 .tq-flex-item {
    flex: none;
    width: calc(10% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-9.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n9.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-9 .tq-flex-item,
  .tq-flex-container.n9 .tq-flex-item {
    flex: none;
    width: calc(11.11111111111111% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-8.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n8.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-8 .tq-flex-item,
  .tq-flex-container.n8 .tq-flex-item {
    flex: none;
    width: calc(12.5% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-7.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n7.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-7 .tq-flex-item,
  .tq-flex-container.n7 .tq-flex-item {
    flex: none;
    width: calc(14.285714285714286% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-6.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n6.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-6 .tq-flex-item,
  .tq-flex-container.n6 .tq-flex-item {
    flex: none;
    width: calc(16.666666666666668% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-5.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n5.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-5 .tq-flex-item,
  .tq-flex-container.n5 .tq-flex-item {
    flex: none;
    width: calc(20% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-4.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n4.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-4 .tq-flex-item,
  .tq-flex-container.n4 .tq-flex-item {
    flex: none;
    width: calc(25% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-3.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n3.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-3 .tq-flex-item,
  .tq-flex-container.n3 .tq-flex-item {
    flex: none;
    width: calc(33.333333333333336% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-2.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n2.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-2 .tq-flex-item,
  .tq-flex-container.n2 .tq-flex-item {
    flex: none;
    width: calc(50% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-m-1.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n1.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-m-1 .tq-flex-item,
  .tq-flex-container.n1 .tq-flex-item {
    flex: none;
    width: calc(100% - 16px);
    box-sizing: border-box;
  }
}
@media (max-width: 768px) {
  .tq-flex-container.tq-flex-item-s-10.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n10.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-10 .tq-flex-item,
  .tq-flex-container.n10 .tq-flex-item {
    flex: none;
    width: calc(10% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-9.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n9.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-9 .tq-flex-item,
  .tq-flex-container.n9 .tq-flex-item {
    flex: none;
    width: calc(11.11111111111111% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-8.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n8.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-8 .tq-flex-item,
  .tq-flex-container.n8 .tq-flex-item {
    flex: none;
    width: calc(12.5% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-7.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n7.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-7 .tq-flex-item,
  .tq-flex-container.n7 .tq-flex-item {
    flex: none;
    width: calc(14.285714285714286% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-6.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n6.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-6 .tq-flex-item,
  .tq-flex-container.n6 .tq-flex-item {
    flex: none;
    width: calc(16.666666666666668% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-5.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n5.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-5 .tq-flex-item,
  .tq-flex-container.n5 .tq-flex-item {
    flex: none;
    width: calc(20% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-4.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n4.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-4 .tq-flex-item,
  .tq-flex-container.n4 .tq-flex-item {
    flex: none;
    width: calc(25% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-3.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n3.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-3 .tq-flex-item,
  .tq-flex-container.n3 .tq-flex-item {
    flex: none;
    width: calc(33.333333333333336% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-2.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n2.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-2 .tq-flex-item,
  .tq-flex-container.n2 .tq-flex-item {
    flex: none;
    width: calc(50% - 16px);
    box-sizing: border-box;
  }
  .tq-flex-container.tq-flex-item-s-1.tq-flex-item-stretch .tq-flex-item,
  .tq-flex-container.n1.tq-flex-item-stretch .tq-flex-item {
    flex-grow: 1;
  }
  .tq-flex-container.tq-flex-item-s-1 .tq-flex-item,
  .tq-flex-container.n1 .tq-flex-item {
    flex: none;
    width: calc(100% - 16px);
    box-sizing: border-box;
  }
}
