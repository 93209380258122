/*@red: #d81f25;*/
/* Control messages variables */
/* ==================================================================== */
/* Color Variables */
/* ==================================================================== */
/* Text ------------------------------ */
/* Panel ----------------------------- */
/* App Header ------------------------ */
/* Components Headers ------------------ */
/* Table ----------------------------- */
/* App Footer ------------------------ */
/* Components Footers ------------------ */
/* Sidebar --------------------------- */
/* Notifications, validation, badges - */
/* Inputs ---------------------------- */
/* Buttons ---------------------- */
/* Interactable ---------------------- */
/* Text ------------------------------ */
.offers-grid-spacing {
  margin-bottom: 40px;
}
.offers-grid-spacing:not(:nth-child(3n+1)) {
  margin-left: 0;
}
.offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
  margin-left: 40px;
}
@media screen and (max-width: 1679px) {
  .offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
    margin-left: 32px;
  }
}
@media screen and (max-width: 1366px) {
  .offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
    margin-left: 25px;
  }
}
@media screen and (max-width: 480px) {
  .offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1679px) {
  .offers-grid-spacing {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 1366px) {
  .offers-grid-spacing {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .offers-grid-spacing:not(:nth-child(4n+1)) {
    margin-left: 0;
  }
  .offers-grid-spacing:not(:nth-child(3n+1)) {
    margin-left: 25px;
  }
}
@media screen and (max-width: 768px) {
  .offers-grid-spacing:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
}
.un-pr-5 {
  padding-right: 3.125rem !important;
}
.un-pl-5 {
  padding-left: 3.125rem !important;
}
.un-pt-5 {
  padding-top: 3.125rem !important;
}
.un-pb-5 {
  padding-bottom: 3.125rem !important;
}
.un-mr-5 {
  margin-right: 3.125rem !important;
}
.un-ml-5 {
  margin-left: 3.125rem !important;
}
.un-mt-5 {
  margin-top: 3.125rem !important;
}
.un-mb-5 {
  margin-bottom: 3.125rem !important;
}
.un-pr-4 {
  padding-right: 2.5rem !important;
}
.un-pl-4 {
  padding-left: 2.5rem !important;
}
.un-pt-4 {
  padding-top: 2.5rem !important;
}
.un-pb-4 {
  padding-bottom: 2.5rem !important;
}
.un-mr-4 {
  margin-right: 2.5rem !important;
}
.un-ml-4 {
  margin-left: 2.5rem !important;
}
.un-mt-4 {
  margin-top: 2.5rem !important;
}
.un-mb-4 {
  margin-bottom: 2.5rem !important;
}
.un-pr-3 {
  padding-right: 1.875rem !important;
}
.un-pl-3 {
  padding-left: 1.875rem !important;
}
.un-pt-3 {
  padding-top: 1.875rem !important;
}
.un-pb-3 {
  padding-bottom: 1.875rem !important;
}
.un-mr-3 {
  margin-right: 1.875rem !important;
}
.un-ml-3 {
  margin-left: 1.875rem !important;
}
.un-mt-3 {
  margin-top: 1.875rem !important;
}
.un-mb-3 {
  margin-bottom: 1.875rem !important;
}
.un-pr-2 {
  padding-right: 1.25rem !important;
}
.un-pl-2 {
  padding-left: 1.25rem !important;
}
.un-pt-2 {
  padding-top: 1.25rem !important;
}
.un-pb-2 {
  padding-bottom: 1.25rem !important;
}
.un-mr-2 {
  margin-right: 1.25rem !important;
}
.un-ml-2 {
  margin-left: 1.25rem !important;
}
.un-mt-2 {
  margin-top: 1.25rem !important;
}
.un-mb-2 {
  margin-bottom: 1.25rem !important;
}
.un-pr-1 {
  padding-right: 0.625rem !important;
}
.un-pl-1 {
  padding-left: 0.625rem !important;
}
.un-pt-1 {
  padding-top: 0.625rem !important;
}
.un-pb-1 {
  padding-bottom: 0.625rem !important;
}
.un-mr-1 {
  margin-right: 0.625rem !important;
}
.un-ml-1 {
  margin-left: 0.625rem !important;
}
.un-mt-1 {
  margin-top: 0.625rem !important;
}
.un-mb-1 {
  margin-bottom: 0.625rem !important;
}
.un-pr-0 {
  padding-right: 0rem !important;
}
.un-pl-0 {
  padding-left: 0rem !important;
}
.un-pt-0 {
  padding-top: 0rem !important;
}
.un-pb-0 {
  padding-bottom: 0rem !important;
}
.un-mr-0 {
  margin-right: 0rem !important;
}
.un-ml-0 {
  margin-left: 0rem !important;
}
.un-mt-0 {
  margin-top: 0rem !important;
}
.un-mb-0 {
  margin-bottom: 0rem !important;
}
.tq-form-element .tq-input-container.tq-checkbox-switch {
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 3px !important;
  min-height: 27px;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option {
  vertical-align: middle;
  z-index: 1;
  flex-grow: 1;
  padding: 4px 10px 4px 9px;
  transition: all 0.4s ease-in-out;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option .tq-checkbox {
  display: none;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option .tq-option-icon {
  margin-right: 10px;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option .tq-option-icon:last-child {
  margin-right: 0;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option .tq-text-node,
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option i[class^="tq-icon-"],
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option i[class^="tq-o-icon-"] {
  display: inline-block;
  margin: 0;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option:hover {
  cursor: pointer;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option.tq-selected {
  cursor: pointer;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option:last-child {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-content .tq-checkbox-option {
  padding-top: 8px;
}
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-content .tq-checkbox-option .tq-option-icon {
  margin: 0;
}
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-content .tq-checkbox-option .tq-text-node,
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-content .tq-checkbox-option i[class^="tq-icon-"],
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-content .tq-checkbox-option i[class^="tq-o-icon-"] {
  display: block;
  margin: 0;
}
.tq-form-element .tq-input-container.tq-vertical-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 3px !important;
}
.tq-form-element .tq-input-container.tq-vertical-layout .tq-checkbox-option {
  box-sizing: border-box;
  padding: 8px 20px;
  flex: 0 0 100%;
  text-align: left !important;
}
.tq-form-element .tq-input-container.tq-vertical-layout .tq-checkbox-option:first-child {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.tq-form-element .tq-input-container.tq-vertical-layout .tq-checkbox-option:last-child {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.tq-form-element.tq-disabled .tq-checkbox-switch > * {
  cursor: not-allowed !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .tq-form-element {
    /* IE10 */
  }
  .tq-form-element .tq-input-container.tq-checkbox-switch {
    display: inline-table;
  }
  .tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option {
    display: table-cell;
  }
  .tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-layout {
    display: inline-block;
  }
  .tq-form-element .tq-input-container.tq-checkbox-switch.tq-vertical-layout .tq-checkbox-option {
    display: block;
  }
}
.tq-form-element .tq-input-container.tq-checkbox-switch {
  background: #F5F5F5;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option {
  text-align: center;
  border-right: 1px solid #F5F5F5;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option:focus {
  outline: none;
  box-shadow: inset 0 0 4px #164194;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option.tq-selected {
  color: #fff;
  background: #164194;
  border-right: 1px solid #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-switch .tq-checkbox-option.tq-selected:focus {
  outline: none;
  box-shadow: inset 0 0 4px #142238;
}
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-has-error {
  box-shadow: 0 0 5px #d91f26;
}
.tq-form-element .tq-input-container.tq-checkbox-switch.tq-has-error .tq-active {
  background: #d91f26;
}
.tq-form-element .tq-input-container.tq-vertical-layout .tq-checkbox-option {
  border-right: none !important;
  border-bottom: 1px solid #F5F5F5;
}
.tq-form-element .tq-input-container.tq-vertical-layout .tq-checkbox-option.tq-selected {
  border-right: none !important;
  border-bottom: 1px solid #1d54c0;
}
.tq-form-element.tq-disabled .tq-checkbox-switch {
  background: #fafafa;
}
.tq-form-element.tq-disabled .tq-checkbox-switch .tq-checkbox-option .tq-text-node {
  color: #EEEEEE;
}
.tq-form-element.tq-disabled .tq-checkbox-switch .tq-checkbox-option.tq-selected {
  background: #5c8be7;
  border-right: 1px solid #5c8be7 !important;
}
.tq-form-element.tq-disabled .tq-checkbox-switch .tq-checkbox-option.tq-selected .tq-text-node {
  color: #fff;
}
/* Checkbox*/
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option {
  display: block;
  width: 100%;
  text-align: left;
  padding-bottom: 8px;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-checkbox {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  overflow: hidden;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-checkbox input[type="checkbox"] {
  opacity: 0;
  display: none;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-checkbox:hover {
  cursor: pointer;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-option-icon {
  margin: 0 5px 0 10px;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-text-node {
  display: inline;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-text-node:hover {
  cursor: pointer;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-text-node + .tq-tooltip-container {
  display: inline-block;
  width: 15px;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-text-node + .tq-tooltip-container:hover {
  cursor: pointer !important;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option:focus {
  outline: none;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option i[class^="tq-icon-"] {
  display: inline-block;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected .tq-checkbox:before {
  display: block;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  padding-left: 1px;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate:not(.tq-selected) .tq-checkbox:before {
  background: none !important;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate .tq-checkbox:before {
  display: block;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 9px;
  position: absolute;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled .tq-checkbox:hover,
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled .tq-checkbox input:hover,
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled .tq-text-node:hover {
  cursor: not-allowed !important;
}
.tq-form-element .tq-input-container.tq-checkbox-group.tq-horizontal-group {
  text-align: left;
}
.tq-form-element .tq-input-container.tq-checkbox-group.tq-horizontal-group .tq-checkbox-option {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}
.tq-form-element .tq-input-container.tq-checkbox-group.tq-horizontal-group .tq-checkbox-option:last-child {
  margin-right: 0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest .tq-checkbox-children {
  padding-left: 25px;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-option.tq-selected {
  padding-left: 10px;
  line-height: 26px;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-children {
  padding-left: 0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-children .tq-checkbox-option {
  padding-left: 25px;
  width: calc(100% - 15px);
}
.tq-form-element .tq-input-container.tq-checkbox-group.tq-break-word .tq-checkbox-option {
  display: flex;
}
.tq-form-element .tq-input-container.tq-checkbox-group.tq-break-word .tq-checkbox-option .tq-text-node {
  flex: 1;
}
.tq-form-element .tq-input-container.tq-checkbox-group.tq-break-word .tq-checkbox-option .tq-checkbox {
  margin-top: 2px;
}
/* Checkbox*/
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-checkbox {
  border: 1px solid #EEEEEE;
  background: #fff;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-checkbox:hover {
  border: 1px solid #164194;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-checkbox:hover:before {
  color: #164194;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option:focus .tq-checkbox {
  border: 1px solid #1d54c0;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option:focus .tq-checkbox:before {
  color: #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-option-icon {
  color: #DBDBDB;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-text-node {
  color: #DBDBDB;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option .tq-text-node + .tq-required:after {
  color: #d91f26;
  font-size: 14px;
  font-style: normal;
  content: '*';
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option i[class^="tq-icon-"] {
  color: #164194;
  transition: all 0.3s ease-in-out;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option i[class^="tq-icon-"]:hover {
  color: #10C891;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected .tq-checkbox {
  border: 1px solid #164194;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected .tq-checkbox:before {
  content: "\e91e";
  font-family: 'tq-icons';
  font-size: 11px;
  line-height: 15px;
  color: #164194;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected .tq-checkbox:hover {
  border: 1px solid #1d54c0;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected .tq-checkbox:hover:before {
  color: #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected:hover .tq-checkbox {
  border: 1px solid #1d54c0;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-selected:hover .tq-checkbox:before {
  color: #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate .tq-checkbox {
  border: 1px solid #164194;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate .tq-checkbox:before {
  content: "";
  background: #164194;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate .tq-checkbox:hover {
  border: 1px solid #1d54c0;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate .tq-checkbox:hover:before {
  background: #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate:hover .tq-checkbox {
  border: 1px solid #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-indeterminate:hover .tq-checkbox:before {
  background: #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled .tq-checkbox {
  background: rgba(200, 200, 200, 0.2);
  color: rgba(44, 52, 64, 0.5);
  border: 1px solid rgba(202, 208, 224, 0.4) !important;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled:hover .tq-checkbox {
  background: rgba(200, 200, 200, 0.2);
  color: rgba(44, 52, 64, 0.5);
  border: 1px solid rgba(202, 208, 224, 0.4) !important;
  box-shadow: none;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled.tq-selected .tq-checkbox:before,
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled.tq-selected:hover:before,
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled.tq-selected .tq-checkbox:hover,
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled.tq-selected:hover:hover {
  color: #DBDBDB;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-disabled.tq-indeterminate .tq-checkbox:before {
  background: #DBDBDB;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-has-error .tq-checkbox {
  border-color: #d91f26 !important;
  box-shadow: 0 0 5px rgba(217, 31, 38, 0.6) !important;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-has-error.tq-selected .tq-checkbox:before {
  color: #d91f26;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-option.tq-has-error.tq-indeterminate .tq-checkbox:before {
  background: #d91f26;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-parent.tq-selected {
  background: #164194;
  border-bottom: 1px solid #1d54c0;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-parent.tq-selected:hover {
  background: #0f2d68;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-parent.tq-selected:focus {
  background: #0f2d68;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-parent.tq-selected .tq-text-node {
  color: #fff;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-children .tq-checkbox-option {
  background: #5c8be7;
  border-bottom: 1px solid #80a5ec;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-children .tq-checkbox-option:focus {
  background: #467ce3;
}
.tq-form-element .tq-input-container.tq-checkbox-group .tq-checkbox-nest.tq-checkbox-accent .tq-checkbox-children .tq-checkbox-option:hover {
  background: #467ce3;
}
.tq-form-element .tq-input-container.tq-has-error .tq-checkbox-option .tq-checkbox {
  border-color: #d91f26 !important;
  box-shadow: 0 0 5px rgba(217, 31, 38, 0.6) !important;
}
.tq-form-element .tq-input-container.tq-has-error .tq-checkbox-option.tq-selected .tq-checkbox:before {
  color: #d91f26 !important;
}
.tq-form-element .tq-input-container.tq-has-error .tq-checkbox-option.tq-indeterminate .tq-checkbox:before {
  background: #d91f26 !important;
}
.tq-form-element .tq-input-container.tq-has-error .tq-help-text {
  color: #d91f26;
}
@media (max-width: 768px) {
  .tq-checkbox-group-container.tq-horizontal {
    flex-direction: column;
  }
  .tq-checkbox-group-container.tq-horizontal .tq-label-container {
    width: 100% !important;
    padding-bottom: 6px;
  }
}
@media (max-width: 480px) {
  .tq-checkbox-group-container .tq-checkbox-group-switch .tq-form-element {
    border-color: #fff;
  }
}
.tq-step-progress-container {
  display: table;
  width: 100%;
  table-layout: fixed;
  padding: 0;
  margin: 0;
}
.tq-step-progress-container .tq-step {
  display: table-cell;
  width: 100%;
  position: relative;
  list-style: none;
}
.tq-step-progress-container .tq-step:before {
  position: absolute;
  content: '';
  right: 50%;
  width: 100%;
  height: 2px;
  top: 27px;
}
.tq-step-progress-container .tq-step:first-child:before {
  content: none;
}
.tq-step-progress-container .tq-step .tq-step-icon {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin: 0 auto 10px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-sizing: border-box;
  z-index: 2;
}
.tq-step-progress-container .tq-step .tq-text-node {
  margin: 0;
  display: block;
}
.tq-step-progress-arrows {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.tq-step-progress-arrows .tq-step {
  display: flex;
  flex-grow: 1;
  position: relative;
  padding: 0;
  margin: 0;
  align-items: center;
  text-align: left;
}
.tq-step-progress-arrows .tq-step:after {
  position: absolute;
  top: 50%;
  right: -13px;
  margin-top: -19px;
  width: 0;
  height: 0;
  content: " ";
  z-index: 3;
}
.tq-step-progress-arrows .tq-step:first-child:before {
  content: "";
}
.tq-step-progress-arrows .tq-step:before {
  position: absolute;
  top: 50%;
  right: -16.7px;
  left: auto;
  margin-top: -19px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 19px 0 19px 14px;
  content: " ";
  z-index: 3;
}
.tq-step-progress-arrows .tq-step:last-child:before {
  display: none;
}
.tq-step-progress-arrows .tq-step:last-child:after {
  display: none;
}
.tq-step-progress-arrows .tq-step:first-child .tq-step-number {
  padding: 10px 13px;
  border-left: none;
}
.tq-step-progress-arrows .tq-step .tq-step-number {
  padding: 10px 13px 10px 23px;
  margin-right: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  align-self: stretch;
}
.tq-step-progress-arrows .tq-step .tq-text-node {
  margin: 0;
  align-self: center;
  padding: 10px 10px 10px 0;
}
.tq-step-progress-valign {
  display: table;
  height: 40px;
}
.tq-step-progress-valign .tq-step .tq-step-icon {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.tq-step-progress-valign .tq-step:before {
  top: 20px;
}
.tq-step-progress-valign .tq-step .tq-text-node {
  display: none;
}
.tq-step-progress-valign .tq-step:hover {
  cursor: pointer;
}
.tq-step-progress-valign .tq-step:hover .tq-step-label {
  position: absolute;
  top: -10px;
  height: 60px;
  width: 100%;
  z-index: 90;
  padding: 0 5px;
  display: table;
}
.tq-step-progress-valign .tq-step:hover .tq-step-label .tq-text-node {
  display: table-cell;
  vertical-align: middle;
}
.tq-step-progress-container .tq-step {
  text-decoration: none;
  text-align: center;
  color: #DBDBDB;
  font-size: 13px;
}
.tq-step-progress-container .tq-step:before {
  background-color: #EEEEEE;
}
.tq-step-progress-container .tq-step .tq-step-icon {
  border: 2px solid rgba(219, 219, 219, 0.2);
  color: rgba(219, 219, 219, 0.35);
  font-size: 22px;
  line-height: 50px;
  background: #fff;
}
.tq-step-progress-container .tq-step.tq-current-step .tq-step-icon {
  border: 2px solid #1d54c0;
  color: #1d54c0;
}
.tq-step-progress-container .tq-step.tq-current-step:before {
  background-color: #1d54c0;
}
.tq-step-progress-container .tq-step.tq-done-step .tq-step-icon {
  background: #1d54c0;
  border: 2px solid #1d54c0;
  color: #fff;
}
.tq-step-progress-container .tq-step.tq-done-step:before {
  background-color: #1d54c0;
}
.tq-step-progress-container .tq-step.tq-incomplete-step .tq-step-icon {
  background: #5c8be7;
  border: 2px dashed #1d54c0;
  color: #164194;
}
.tq-step-progress-container .tq-step.tq-incomplete-step:before {
  background-color: #1d54c0;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-first:before {
  background-color: #10C891;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-current-step .tq-step-icon {
  border: 2px solid #10C891;
  color: #10C891;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-current-step:before {
  background-color: #10C891;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-done-step .tq-step-icon {
  background: #10C891;
  border: 2px solid #10C891;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-done-step:before {
  background-color: #10C891;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-incomplete-step .tq-step-icon {
  background: #7cf5d1;
  border: 2px dashed #10C891;
  color: #10C891;
}
.tq-step-progress-container.tq-color2 .tq-step.tq-incomplete-step:before {
  background-color: #10C891;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step {
  background: #ffffff;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step:after {
  border-style: solid;
  border-width: 19px 0 19px 14px;
  border-color: transparent transparent transparent #ffffff;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step:before {
  border-color: transparent transparent transparent #fff;
  background-color: transparent !important;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step .tq-step-number {
  background: #EEEEEE;
  border-left: 4px solid #fff;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step.tq-current-step {
  background: #164194 !important;
  color: #fff;
  box-sizing: border-box;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step.tq-current-step:after {
  border-color: transparent transparent transparent #164194;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step.tq-current-step .tq-step-number {
  background: #306ce0 !important;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step.tq-done-step {
  background: #1d54c0 !important;
  color: #fff;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step.tq-done-step:after {
  border-color: transparent transparent transparent #1d54c0;
}
.tq-step-progress-container.tq-step-progress-arrows .tq-step.tq-done-step .tq-step-number {
  background: #164194 !important;
}
.tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-current-step {
  background: #10C891 !important;
  color: #fff;
}
.tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-current-step:after {
  border-color: transparent transparent transparent #10C891;
}
.tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-current-step .tq-step-number {
  background: #0c996f !important;
}
.tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-done-step {
  background: #1eedaf !important;
  color: #fff;
}
.tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-done-step:after {
  border-color: transparent transparent transparent #1eedaf;
}
.tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-done-step .tq-step-number {
  background: #10C891 !important;
}
.tq-step-progress-container.tq-step-progress-valign .tq-step .tq-step-icon {
  line-height: 38px;
  font-size: 16px;
}
.tq-step-progress-container.tq-step-progress-valign .tq-step:hover {
  cursor: pointer;
}
.tq-step-progress-container.tq-step-progress-valign .tq-step:hover .tq-step-label {
  background: #fff;
  line-height: 20px;
}
.tq-accent .tq-step-progress-container .tq-step {
  color: #fff;
}
.tq-accent .tq-step-progress-container .tq-step:before {
  background-color: rgba(255, 255, 255, 0.4);
}
.tq-accent .tq-step-progress-container .tq-step.tq-first:after {
  background-color: #306ce0;
}
.tq-accent .tq-step-progress-container .tq-step.tq-first:before {
  background-color: #306ce0;
}
.tq-accent .tq-step-progress-container .tq-step .tq-step-icon {
  border: 2px solid rgba(255, 255, 255, 0.4);
  background: #DBDBDB;
  color: rgba(255, 255, 255, 0.4);
}
.tq-accent .tq-step-progress-container .tq-step .tq-text-node {
  margin: 0;
}
.tq-accent .tq-step-progress-container .tq-step.tq-current-step:before {
  background-color: #306ce0;
}
.tq-accent .tq-step-progress-container .tq-step.tq-current-step .tq-step-icon {
  border: 2px solid #306ce0;
  background: #DBDBDB;
  color: #306ce0;
}
.tq-accent .tq-step-progress-container .tq-step.tq-done-step:before {
  background-color: #306ce0;
}
.tq-accent .tq-step-progress-container .tq-step.tq-done-step .tq-step-icon {
  background: #306ce0;
  border: 2px solid #306ce0;
  color: #fff;
}
.tq-accent .tq-step-progress-container.tq-color2 .tq-step.tq-first:before {
  background-color: #12e0a2;
}
.tq-accent .tq-step-progress-container.tq-color2 .tq-step.tq-current-step:before {
  background-color: #12e0a2 !important;
}
.tq-accent .tq-step-progress-container.tq-color2 .tq-step.tq-current-step .tq-step-icon {
  border: 2px solid #12e0a2;
  color: #12e0a2;
}
.tq-accent .tq-step-progress-container.tq-color2 .tq-step.tq-done-step:before {
  background-color: #12e0a2 !important;
}
.tq-accent .tq-step-progress-container.tq-color2 .tq-step.tq-done-step .tq-step-icon {
  background: #12e0a2;
  border: 2px solid #12e0a2;
}
.tq-accent .tq-step-progress-container.tq-step-progress-arrows .tq-step {
  background: #cecece;
}
.tq-accent .tq-step-progress-container.tq-step-progress-arrows .tq-step:before {
  border-color: transparent transparent transparent #DBDBDB;
}
.tq-accent .tq-step-progress-container.tq-step-progress-arrows .tq-step .tq-step-number {
  background: #c2c2c2;
  border-left: 4px solid #DBDBDB;
}
.tq-accent .tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-step {
  background: #cecece;
}
.tq-accent .tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-step:before {
  border-color: transparent transparent transparent #DBDBDB;
}
.tq-accent .tq-step-progress-container.tq-step-progress-arrows.tq-color2-arrows .tq-step .tq-step-number {
  background: #c2c2c2;
  border-left: 4px solid #DBDBDB;
}
@media (max-width: 768px) {
  .tq-step-progress-arrows .tq-step .tq-text-node {
    display: none;
  }
  .tq-step-progress-valign .tq-step:hover .tq-step-label {
    display: none;
  }
}
@media (max-width: 768px) {
  .tq-step-progress-arrows .tq-step {
    font-size: 15px;
    background: #EEEEEE;
  }
  .tq-step-progress-arrows .tq-step .tq-step-number {
    background: #EEEEEE;
  }
  .tq-step-progress-arrows .tq-step.tq-current-step {
    background: #0f2d68 !important;
  }
  .tq-step-progress-arrows .tq-step.tq-current-step:after {
    border-color: transparent transparent transparent #0f2d68;
  }
  .tq-step-progress-arrows .tq-step.tq-current-step .tq-step-number {
    background: #0f2d68 !important;
  }
  .tq-step-progress-arrows .tq-step.tq-done-step {
    background: #164194 !important;
  }
  .tq-step-progress-arrows .tq-step.tq-done-step:after {
    border-color: transparent transparent transparent #164194;
  }
  .tq-step-progress-arrows .tq-step.tq-done-step .tq-step-number {
    background: #164194 !important;
  }
  .tq-step-progress-arrows.tq-orange-arrows .tq-current-step {
    background: #0c996f !important;
  }
  .tq-step-progress-arrows.tq-orange-arrows .tq-current-step:after {
    border-color: transparent transparent transparent #0c996f;
  }
  .tq-step-progress-arrows.tq-orange-arrows .tq-current-step .tq-step-number {
    background: #0c996f !important;
  }
  .tq-step-progress-arrows.tq-orange-arrows .tq-done-step {
    background: #10C891 !important;
  }
  .tq-step-progress-arrows.tq-orange-arrows .tq-done-step:after {
    border-color: transparent transparent transparent #10C891;
  }
  .tq-step-progress-arrows.tq-orange-arrows .tq-done-step .tq-step-number {
    background: #10C891 !important;
  }
}
.tq-text-node {
  display: inline-block;
}
.tq-text-node + * {
  margin-left: 5px;
}
* + .tq-text-node {
  margin-left: 5px;
}
.tq-form-element .tq-output-container.tq-output-list ul {
  margin-top: 0;
  margin-bottom: 0;
}
.tq-form-element .tq-output-container.tq-output-list ul li .tq-item .tq-text-node {
  line-height: 14px;
}
.tq-form-element .tq-output-container.tq-output-thumbnail.tq-output-thumb-max-width .tq-thumbnail-container {
  width: 120px;
  padding-top: 120px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .tq-form-element .tq-label-container.tq-break-word .tq-text-node {
    word-break: break-all;
  }
  .tq-form-element .tq-output-container.tq-break-word .tq-text-node {
    word-break: break-all;
  }
}
#tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-subtitle p,
#tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-subtitle small,
#tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-subtitle small p {
  font-weight: 400;
}
@media screen and (max-width: 1679px) {
  #tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-title h1.tq-title span,
  #tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-title h1.tq-title p {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
}
@media screen and (max-width: 480px) {
  #tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-title h1.tq-title span,
  #tq-uneo.oav-content .uneo-cp .un-oav-wrapper .un-page-title h1.tq-title p {
    font-size: 35px;
    line-height: 35px;
  }
}
#tq-uneo.oav-content .un-buttons-sticky-wrapper,
#tq-uneo.oav-content #chatbot-insert,
#tq-uneo.oav-content .un-contact-box {
  display: none;
}
