/*@red: #d81f25;*/
/* Control messages variables */
/* ==================================================================== */
/* Color Variables */
/* ==================================================================== */
/* Text ------------------------------ */
/* Panel ----------------------------- */
/* App Header ------------------------ */
/* Components Headers ------------------ */
/* Table ----------------------------- */
/* App Footer ------------------------ */
/* Components Footers ------------------ */
/* Sidebar --------------------------- */
/* Notifications, validation, badges - */
/* Inputs ---------------------------- */
/* Buttons ---------------------- */
/* Interactable ---------------------- */
/* Text ------------------------------ */
.offers-grid-spacing {
  margin-bottom: 40px;
}
.offers-grid-spacing:not(:nth-child(3n+1)) {
  margin-left: 0;
}
.offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
  margin-left: 40px;
}
@media screen and (max-width: 1679px) {
  .offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
    margin-left: 32px;
  }
}
@media screen and (max-width: 1366px) {
  .offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
    margin-left: 25px;
  }
}
@media screen and (max-width: 480px) {
  .offers-grid-spacing:not(:nth-child(4n+1)):not(:first-child:last-child) {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1679px) {
  .offers-grid-spacing {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 1366px) {
  .offers-grid-spacing {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .offers-grid-spacing:not(:nth-child(4n+1)) {
    margin-left: 0;
  }
  .offers-grid-spacing:not(:nth-child(3n+1)) {
    margin-left: 25px;
  }
}
@media screen and (max-width: 768px) {
  .offers-grid-spacing:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
}
.un-pr-5 {
  padding-right: 3.125rem !important;
}
.un-pl-5 {
  padding-left: 3.125rem !important;
}
.un-pt-5 {
  padding-top: 3.125rem !important;
}
.un-pb-5 {
  padding-bottom: 3.125rem !important;
}
.un-mr-5 {
  margin-right: 3.125rem !important;
}
.un-ml-5 {
  margin-left: 3.125rem !important;
}
.un-mt-5 {
  margin-top: 3.125rem !important;
}
.un-mb-5 {
  margin-bottom: 3.125rem !important;
}
.un-pr-4 {
  padding-right: 2.5rem !important;
}
.un-pl-4 {
  padding-left: 2.5rem !important;
}
.un-pt-4 {
  padding-top: 2.5rem !important;
}
.un-pb-4 {
  padding-bottom: 2.5rem !important;
}
.un-mr-4 {
  margin-right: 2.5rem !important;
}
.un-ml-4 {
  margin-left: 2.5rem !important;
}
.un-mt-4 {
  margin-top: 2.5rem !important;
}
.un-mb-4 {
  margin-bottom: 2.5rem !important;
}
.un-pr-3 {
  padding-right: 1.875rem !important;
}
.un-pl-3 {
  padding-left: 1.875rem !important;
}
.un-pt-3 {
  padding-top: 1.875rem !important;
}
.un-pb-3 {
  padding-bottom: 1.875rem !important;
}
.un-mr-3 {
  margin-right: 1.875rem !important;
}
.un-ml-3 {
  margin-left: 1.875rem !important;
}
.un-mt-3 {
  margin-top: 1.875rem !important;
}
.un-mb-3 {
  margin-bottom: 1.875rem !important;
}
.un-pr-2 {
  padding-right: 1.25rem !important;
}
.un-pl-2 {
  padding-left: 1.25rem !important;
}
.un-pt-2 {
  padding-top: 1.25rem !important;
}
.un-pb-2 {
  padding-bottom: 1.25rem !important;
}
.un-mr-2 {
  margin-right: 1.25rem !important;
}
.un-ml-2 {
  margin-left: 1.25rem !important;
}
.un-mt-2 {
  margin-top: 1.25rem !important;
}
.un-mb-2 {
  margin-bottom: 1.25rem !important;
}
.un-pr-1 {
  padding-right: 0.625rem !important;
}
.un-pl-1 {
  padding-left: 0.625rem !important;
}
.un-pt-1 {
  padding-top: 0.625rem !important;
}
.un-pb-1 {
  padding-bottom: 0.625rem !important;
}
.un-mr-1 {
  margin-right: 0.625rem !important;
}
.un-ml-1 {
  margin-left: 0.625rem !important;
}
.un-mt-1 {
  margin-top: 0.625rem !important;
}
.un-mb-1 {
  margin-bottom: 0.625rem !important;
}
.un-pr-0 {
  padding-right: 0rem !important;
}
.un-pl-0 {
  padding-left: 0rem !important;
}
.un-pt-0 {
  padding-top: 0rem !important;
}
.un-pb-0 {
  padding-bottom: 0rem !important;
}
.un-mr-0 {
  margin-right: 0rem !important;
}
.un-ml-0 {
  margin-left: 0rem !important;
}
.un-mt-0 {
  margin-top: 0rem !important;
}
.un-mb-0 {
  margin-bottom: 0rem !important;
}
.tq-inline-container {
  display: inline-block;
}
.tq-inline-container > div.tq-value-width-50 > .tq-text-input,
.tq-inline-container > div.tq-value-width-50 > .tq-select,
.tq-inline-container > div.tq-value-width-50 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-50 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-50 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-50 > .tq-vertical,
.tq-inline-container > div.tq-value-width-50 > .tq-radio-switch {
  width: 50px !important;
}
.tq-inline-container > div.tq-value-width-60 > .tq-text-input,
.tq-inline-container > div.tq-value-width-60 > .tq-select,
.tq-inline-container > div.tq-value-width-60 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-60 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-60 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-60 > .tq-vertical,
.tq-inline-container > div.tq-value-width-60 > .tq-radio-switch {
  width: 60px !important;
}
.tq-inline-container > div.tq-value-width-70 > .tq-text-input,
.tq-inline-container > div.tq-value-width-70 > .tq-select,
.tq-inline-container > div.tq-value-width-70 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-70 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-70 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-70 > .tq-vertical,
.tq-inline-container > div.tq-value-width-70 > .tq-radio-switch {
  width: 70px !important;
}
.tq-inline-container > div.tq-value-width-80 > .tq-text-input,
.tq-inline-container > div.tq-value-width-80 > .tq-select,
.tq-inline-container > div.tq-value-width-80 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-80 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-80 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-80 > .tq-vertical,
.tq-inline-container > div.tq-value-width-80 > .tq-radio-switch {
  width: 80px !important;
}
.tq-inline-container > div.tq-value-width-90 > .tq-text-input,
.tq-inline-container > div.tq-value-width-90 > .tq-select,
.tq-inline-container > div.tq-value-width-90 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-90 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-90 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-90 > .tq-vertical,
.tq-inline-container > div.tq-value-width-90 > .tq-radio-switch {
  width: 90px !important;
}
.tq-inline-container > div.tq-value-width-100 > .tq-text-input,
.tq-inline-container > div.tq-value-width-100 > .tq-select,
.tq-inline-container > div.tq-value-width-100 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-100 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-100 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-100 > .tq-vertical,
.tq-inline-container > div.tq-value-width-100 > .tq-radio-switch {
  width: 100px !important;
}
.tq-inline-container > div.tq-value-width-110 > .tq-text-input,
.tq-inline-container > div.tq-value-width-110 > .tq-select,
.tq-inline-container > div.tq-value-width-110 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-110 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-110 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-110 > .tq-vertical,
.tq-inline-container > div.tq-value-width-110 > .tq-radio-switch {
  width: 110px !important;
}
.tq-inline-container > div.tq-value-width-120 > .tq-text-input,
.tq-inline-container > div.tq-value-width-120 > .tq-select,
.tq-inline-container > div.tq-value-width-120 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-120 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-120 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-120 > .tq-vertical,
.tq-inline-container > div.tq-value-width-120 > .tq-radio-switch {
  width: 120px !important;
}
.tq-inline-container > div.tq-value-width-130 > .tq-text-input,
.tq-inline-container > div.tq-value-width-130 > .tq-select,
.tq-inline-container > div.tq-value-width-130 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-130 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-130 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-130 > .tq-vertical,
.tq-inline-container > div.tq-value-width-130 > .tq-radio-switch {
  width: 130px !important;
}
.tq-inline-container > div.tq-value-width-140 > .tq-text-input,
.tq-inline-container > div.tq-value-width-140 > .tq-select,
.tq-inline-container > div.tq-value-width-140 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-140 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-140 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-140 > .tq-vertical,
.tq-inline-container > div.tq-value-width-140 > .tq-radio-switch {
  width: 140px !important;
}
.tq-inline-container > div.tq-value-width-150 > .tq-text-input,
.tq-inline-container > div.tq-value-width-150 > .tq-select,
.tq-inline-container > div.tq-value-width-150 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-150 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-150 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-150 > .tq-vertical,
.tq-inline-container > div.tq-value-width-150 > .tq-radio-switch {
  width: 150px !important;
}
.tq-inline-container > div.tq-value-width-160 > .tq-text-input,
.tq-inline-container > div.tq-value-width-160 > .tq-select,
.tq-inline-container > div.tq-value-width-160 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-160 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-160 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-160 > .tq-vertical,
.tq-inline-container > div.tq-value-width-160 > .tq-radio-switch {
  width: 160px !important;
}
.tq-inline-container > div.tq-value-width-170 > .tq-text-input,
.tq-inline-container > div.tq-value-width-170 > .tq-select,
.tq-inline-container > div.tq-value-width-170 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-170 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-170 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-170 > .tq-vertical,
.tq-inline-container > div.tq-value-width-170 > .tq-radio-switch {
  width: 170px !important;
}
.tq-inline-container > div.tq-value-width-180 > .tq-text-input,
.tq-inline-container > div.tq-value-width-180 > .tq-select,
.tq-inline-container > div.tq-value-width-180 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-180 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-180 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-180 > .tq-vertical,
.tq-inline-container > div.tq-value-width-180 > .tq-radio-switch {
  width: 180px !important;
}
.tq-inline-container > div.tq-value-width-190 > .tq-text-input,
.tq-inline-container > div.tq-value-width-190 > .tq-select,
.tq-inline-container > div.tq-value-width-190 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-190 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-190 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-190 > .tq-vertical,
.tq-inline-container > div.tq-value-width-190 > .tq-radio-switch {
  width: 190px !important;
}
.tq-inline-container > div.tq-value-width-200 > .tq-text-input,
.tq-inline-container > div.tq-value-width-200 > .tq-select,
.tq-inline-container > div.tq-value-width-200 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-200 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-200 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-200 > .tq-vertical,
.tq-inline-container > div.tq-value-width-200 > .tq-radio-switch {
  width: 200px !important;
}
.tq-inline-container > div.tq-value-width-210 > .tq-text-input,
.tq-inline-container > div.tq-value-width-210 > .tq-select,
.tq-inline-container > div.tq-value-width-210 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-210 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-210 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-210 > .tq-vertical,
.tq-inline-container > div.tq-value-width-210 > .tq-radio-switch {
  width: 210px !important;
}
.tq-inline-container > div.tq-value-width-220 > .tq-text-input,
.tq-inline-container > div.tq-value-width-220 > .tq-select,
.tq-inline-container > div.tq-value-width-220 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-220 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-220 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-220 > .tq-vertical,
.tq-inline-container > div.tq-value-width-220 > .tq-radio-switch {
  width: 220px !important;
}
.tq-inline-container > div.tq-value-width-230 > .tq-text-input,
.tq-inline-container > div.tq-value-width-230 > .tq-select,
.tq-inline-container > div.tq-value-width-230 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-230 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-230 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-230 > .tq-vertical,
.tq-inline-container > div.tq-value-width-230 > .tq-radio-switch {
  width: 230px !important;
}
.tq-inline-container > div.tq-value-width-240 > .tq-text-input,
.tq-inline-container > div.tq-value-width-240 > .tq-select,
.tq-inline-container > div.tq-value-width-240 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-240 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-240 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-240 > .tq-vertical,
.tq-inline-container > div.tq-value-width-240 > .tq-radio-switch {
  width: 240px !important;
}
.tq-inline-container > div.tq-value-width-250 > .tq-text-input,
.tq-inline-container > div.tq-value-width-250 > .tq-select,
.tq-inline-container > div.tq-value-width-250 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-250 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-250 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-250 > .tq-vertical,
.tq-inline-container > div.tq-value-width-250 > .tq-radio-switch {
  width: 250px !important;
}
.tq-inline-container > div.tq-value-width-260 > .tq-text-input,
.tq-inline-container > div.tq-value-width-260 > .tq-select,
.tq-inline-container > div.tq-value-width-260 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-260 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-260 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-260 > .tq-vertical,
.tq-inline-container > div.tq-value-width-260 > .tq-radio-switch {
  width: 260px !important;
}
.tq-inline-container > div.tq-value-width-270 > .tq-text-input,
.tq-inline-container > div.tq-value-width-270 > .tq-select,
.tq-inline-container > div.tq-value-width-270 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-270 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-270 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-270 > .tq-vertical,
.tq-inline-container > div.tq-value-width-270 > .tq-radio-switch {
  width: 270px !important;
}
.tq-inline-container > div.tq-value-width-280 > .tq-text-input,
.tq-inline-container > div.tq-value-width-280 > .tq-select,
.tq-inline-container > div.tq-value-width-280 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-280 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-280 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-280 > .tq-vertical,
.tq-inline-container > div.tq-value-width-280 > .tq-radio-switch {
  width: 280px !important;
}
.tq-inline-container > div.tq-value-width-290 > .tq-text-input,
.tq-inline-container > div.tq-value-width-290 > .tq-select,
.tq-inline-container > div.tq-value-width-290 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-290 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-290 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-290 > .tq-vertical,
.tq-inline-container > div.tq-value-width-290 > .tq-radio-switch {
  width: 290px !important;
}
.tq-inline-container > div.tq-value-width-300 > .tq-text-input,
.tq-inline-container > div.tq-value-width-300 > .tq-select,
.tq-inline-container > div.tq-value-width-300 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-300 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-300 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-300 > .tq-vertical,
.tq-inline-container > div.tq-value-width-300 > .tq-radio-switch {
  width: 300px !important;
}
.tq-inline-container > div.tq-value-width-310 > .tq-text-input,
.tq-inline-container > div.tq-value-width-310 > .tq-select,
.tq-inline-container > div.tq-value-width-310 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-310 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-310 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-310 > .tq-vertical,
.tq-inline-container > div.tq-value-width-310 > .tq-radio-switch {
  width: 310px !important;
}
.tq-inline-container > div.tq-value-width-320 > .tq-text-input,
.tq-inline-container > div.tq-value-width-320 > .tq-select,
.tq-inline-container > div.tq-value-width-320 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-320 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-320 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-320 > .tq-vertical,
.tq-inline-container > div.tq-value-width-320 > .tq-radio-switch {
  width: 320px !important;
}
.tq-inline-container > div.tq-value-width-330 > .tq-text-input,
.tq-inline-container > div.tq-value-width-330 > .tq-select,
.tq-inline-container > div.tq-value-width-330 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-330 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-330 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-330 > .tq-vertical,
.tq-inline-container > div.tq-value-width-330 > .tq-radio-switch {
  width: 330px !important;
}
.tq-inline-container > div.tq-value-width-340 > .tq-text-input,
.tq-inline-container > div.tq-value-width-340 > .tq-select,
.tq-inline-container > div.tq-value-width-340 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-340 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-340 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-340 > .tq-vertical,
.tq-inline-container > div.tq-value-width-340 > .tq-radio-switch {
  width: 340px !important;
}
.tq-inline-container > div.tq-value-width-350 > .tq-text-input,
.tq-inline-container > div.tq-value-width-350 > .tq-select,
.tq-inline-container > div.tq-value-width-350 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-350 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-350 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-350 > .tq-vertical,
.tq-inline-container > div.tq-value-width-350 > .tq-radio-switch {
  width: 350px !important;
}
.tq-inline-container > div.tq-value-width-360 > .tq-text-input,
.tq-inline-container > div.tq-value-width-360 > .tq-select,
.tq-inline-container > div.tq-value-width-360 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-360 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-360 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-360 > .tq-vertical,
.tq-inline-container > div.tq-value-width-360 > .tq-radio-switch {
  width: 360px !important;
}
.tq-inline-container > div.tq-value-width-370 > .tq-text-input,
.tq-inline-container > div.tq-value-width-370 > .tq-select,
.tq-inline-container > div.tq-value-width-370 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-370 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-370 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-370 > .tq-vertical,
.tq-inline-container > div.tq-value-width-370 > .tq-radio-switch {
  width: 370px !important;
}
.tq-inline-container > div.tq-value-width-380 > .tq-text-input,
.tq-inline-container > div.tq-value-width-380 > .tq-select,
.tq-inline-container > div.tq-value-width-380 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-380 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-380 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-380 > .tq-vertical,
.tq-inline-container > div.tq-value-width-380 > .tq-radio-switch {
  width: 380px !important;
}
.tq-inline-container > div.tq-value-width-390 > .tq-text-input,
.tq-inline-container > div.tq-value-width-390 > .tq-select,
.tq-inline-container > div.tq-value-width-390 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-390 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-390 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-390 > .tq-vertical,
.tq-inline-container > div.tq-value-width-390 > .tq-radio-switch {
  width: 390px !important;
}
.tq-inline-container > div.tq-value-width-400 > .tq-text-input,
.tq-inline-container > div.tq-value-width-400 > .tq-select,
.tq-inline-container > div.tq-value-width-400 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-400 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-400 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-400 > .tq-vertical,
.tq-inline-container > div.tq-value-width-400 > .tq-radio-switch {
  width: 400px !important;
}
.tq-inline-container > div.tq-value-width-410 > .tq-text-input,
.tq-inline-container > div.tq-value-width-410 > .tq-select,
.tq-inline-container > div.tq-value-width-410 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-410 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-410 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-410 > .tq-vertical,
.tq-inline-container > div.tq-value-width-410 > .tq-radio-switch {
  width: 410px !important;
}
.tq-inline-container > div.tq-value-width-420 > .tq-text-input,
.tq-inline-container > div.tq-value-width-420 > .tq-select,
.tq-inline-container > div.tq-value-width-420 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-420 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-420 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-420 > .tq-vertical,
.tq-inline-container > div.tq-value-width-420 > .tq-radio-switch {
  width: 420px !important;
}
.tq-inline-container > div.tq-value-width-430 > .tq-text-input,
.tq-inline-container > div.tq-value-width-430 > .tq-select,
.tq-inline-container > div.tq-value-width-430 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-430 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-430 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-430 > .tq-vertical,
.tq-inline-container > div.tq-value-width-430 > .tq-radio-switch {
  width: 430px !important;
}
.tq-inline-container > div.tq-value-width-440 > .tq-text-input,
.tq-inline-container > div.tq-value-width-440 > .tq-select,
.tq-inline-container > div.tq-value-width-440 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-440 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-440 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-440 > .tq-vertical,
.tq-inline-container > div.tq-value-width-440 > .tq-radio-switch {
  width: 440px !important;
}
.tq-inline-container > div.tq-value-width-450 > .tq-text-input,
.tq-inline-container > div.tq-value-width-450 > .tq-select,
.tq-inline-container > div.tq-value-width-450 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-450 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-450 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-450 > .tq-vertical,
.tq-inline-container > div.tq-value-width-450 > .tq-radio-switch {
  width: 450px !important;
}
.tq-inline-container > div.tq-value-width-460 > .tq-text-input,
.tq-inline-container > div.tq-value-width-460 > .tq-select,
.tq-inline-container > div.tq-value-width-460 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-460 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-460 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-460 > .tq-vertical,
.tq-inline-container > div.tq-value-width-460 > .tq-radio-switch {
  width: 460px !important;
}
.tq-inline-container > div.tq-value-width-470 > .tq-text-input,
.tq-inline-container > div.tq-value-width-470 > .tq-select,
.tq-inline-container > div.tq-value-width-470 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-470 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-470 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-470 > .tq-vertical,
.tq-inline-container > div.tq-value-width-470 > .tq-radio-switch {
  width: 470px !important;
}
.tq-inline-container > div.tq-value-width-480 > .tq-text-input,
.tq-inline-container > div.tq-value-width-480 > .tq-select,
.tq-inline-container > div.tq-value-width-480 > .tq-file-upload,
.tq-inline-container > div.tq-value-width-480 > .tq-range-slider,
.tq-inline-container > div.tq-value-width-480 > .tq-checkbox-switch,
.tq-inline-container > div.tq-value-width-480 > .tq-vertical,
.tq-inline-container > div.tq-value-width-480 > .tq-radio-switch {
  width: 480px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-50 > .tq-radio-switch {
  width: 50px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-60 > .tq-radio-switch {
  width: 60px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-70 > .tq-radio-switch {
  width: 70px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-80 > .tq-radio-switch {
  width: 80px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-90 > .tq-radio-switch {
  width: 90px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-100 > .tq-radio-switch {
  width: 100px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-110 > .tq-radio-switch {
  width: 110px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-120 > .tq-radio-switch {
  width: 120px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-130 > .tq-radio-switch {
  width: 130px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-140 > .tq-radio-switch {
  width: 140px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-150 > .tq-radio-switch {
  width: 150px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-160 > .tq-radio-switch {
  width: 160px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-170 > .tq-radio-switch {
  width: 170px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-180 > .tq-radio-switch {
  width: 180px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-190 > .tq-radio-switch {
  width: 190px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-200 > .tq-radio-switch {
  width: 200px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-210 > .tq-radio-switch {
  width: 210px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-220 > .tq-radio-switch {
  width: 220px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-230 > .tq-radio-switch {
  width: 230px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-240 > .tq-radio-switch {
  width: 240px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-250 > .tq-radio-switch {
  width: 250px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-260 > .tq-radio-switch {
  width: 260px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-270 > .tq-radio-switch {
  width: 270px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-280 > .tq-radio-switch {
  width: 280px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-290 > .tq-radio-switch {
  width: 290px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-300 > .tq-radio-switch {
  width: 300px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-310 > .tq-radio-switch {
  width: 310px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-320 > .tq-radio-switch {
  width: 320px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-330 > .tq-radio-switch {
  width: 330px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-340 > .tq-radio-switch {
  width: 340px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-350 > .tq-radio-switch {
  width: 350px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-360 > .tq-radio-switch {
  width: 360px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-370 > .tq-radio-switch {
  width: 370px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-380 > .tq-radio-switch {
  width: 380px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-390 > .tq-radio-switch {
  width: 390px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-400 > .tq-radio-switch {
  width: 400px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-410 > .tq-radio-switch {
  width: 410px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-420 > .tq-radio-switch {
  width: 420px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-430 > .tq-radio-switch {
  width: 430px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-440 > .tq-radio-switch {
  width: 440px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-450 > .tq-radio-switch {
  width: 450px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-460 > .tq-radio-switch {
  width: 460px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-470 > .tq-radio-switch {
  width: 470px !important;
}
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-text-input,
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-select,
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-file-upload,
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-range-slider,
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-checkbox-switch,
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-vertical,
.tq-inline-container > .tq-inline > div.tq-value-width-480 > .tq-radio-switch {
  width: 480px !important;
}
.tq-inline-container.tq-inline-margin-20 > * {
  margin-right: 20px;
}
.tq-inline-container.tq-inline-margin-20 > *:last-child {
  margin-right: 0;
}
.tq-inline-container.tq-inline-margin-10 > * {
  margin-right: 10px;
}
.tq-inline-container.tq-inline-margin-10 > *:last-child {
  margin-right: 0;
}
.tq-inline-container .tq-label-value-container {
  display: inline-flex;
  margin-top: 8px;
}
.tq-inline-container .tq-label-value-container .tq-label-container {
  display: inline;
  width: auto;
  line-height: 27px;
}
.tq-inline-container .tq-form-element {
  display: inline-flex;
  margin-top: 16px;
  vertical-align: top;
  max-height: 27px;
}
.tq-inline-container .tq-form-element.tq-no-label {
  width: auto;
}
.tq-inline-container .tq-form-element.tq-no-label .tq-label-container {
  display: none;
  width: auto;
}
.tq-inline-container .tq-form-element.tq-no-label .tq-range-slider {
  min-width: 250px;
}
.tq-inline-container .tq-form-element .tq-label-container,
.tq-inline-container .tq-form-element .tq-output-container {
  vertical-align: top;
  display: inline-block;
  width: auto;
  margin-top: 4px;
}
.tq-inline-container .tq-form-element .tq-text-input,
.tq-inline-container .tq-form-element .tq-datepicker-input,
.tq-inline-container .tq-form-element .tq-file-upload,
.tq-inline-container .tq-form-element .tq-select {
  display: inline-block;
}
.tq-inline-container .tq-form-element .tq-text-input,
.tq-inline-container .tq-form-element .tq-number-input,
.tq-inline-container .tq-form-element .tq-datepicker-input,
.tq-inline-container .tq-form-element .tq-file-upload,
.tq-inline-container .tq-form-element .tq-select {
  width: auto;
}
.tq-inline-container .tq-form-element .tq-checkbox-group .tq-checkbox-option {
  margin-top: 4px;
  padding-bottom: 4px;
}
.tq-inline-container .tq-form-element .tq-radio-group .tq-radio-option {
  margin-top: 4px;
  padding-bottom: 4px;
}
.tq-inline-container .tq-form-element .tq-number-input {
  display: inline-flex;
}
.tq-inline-container .tq-form-element .tq-number-input .tq-number-input-arrows {
  display: inline-block;
  float: left;
}
.tq-inline-container .tq-form-element .tq-number-input .tq-select {
  display: inline-block;
  float: right;
}
.tq-inline-container .tq-form-element .tq-number-input:after {
  content: '';
  clear: both;
}
.tq-inline-container .tq-form-element .tq-radio-group,
.tq-inline-container .tq-form-element .tq-radio-onoff,
.tq-inline-container .tq-form-element .tq-checkbox-group {
  display: inline;
  width: auto;
}
.tq-inline-container .tq-form-element .tq-radio-group .tq-radio-option,
.tq-inline-container .tq-form-element .tq-radio-onoff .tq-radio-option,
.tq-inline-container .tq-form-element .tq-checkbox-group .tq-radio-option,
.tq-inline-container .tq-form-element .tq-radio-group .tq-checkbox-option,
.tq-inline-container .tq-form-element .tq-radio-onoff .tq-checkbox-option,
.tq-inline-container .tq-form-element .tq-checkbox-group .tq-checkbox-option,
.tq-inline-container .tq-form-element .tq-radio-group .tq-radio,
.tq-inline-container .tq-form-element .tq-radio-onoff .tq-radio,
.tq-inline-container .tq-form-element .tq-checkbox-group .tq-radio {
  display: inline-block;
}
.tq-inline-container .tq-form-element .tq-radio-switch,
.tq-inline-container .tq-form-element .tq-checkbox-switch {
  display: inline-block;
  width: auto;
}
.tq-inline-container .tq-form-element .tq-radio-switch .tq-radio-option,
.tq-inline-container .tq-form-element .tq-checkbox-switch .tq-radio-option,
.tq-inline-container .tq-form-element .tq-radio-switch .tq-checkbox-option,
.tq-inline-container .tq-form-element .tq-checkbox-switch .tq-checkbox-option {
  position: relative;
  display: inline-block;
}
.tq-inline-container .tq-form-element .tq-range-slider {
  display: inline-flex;
  width: 250px;
  min-width: 250px;
}
.tq-inline-container .tq-form-element .tq-range-slider.tq-range-input .InputRange {
  float: left;
  width: calc(100% - 80px);
}
.tq-inline-container .tq-form-element .tq-range-slider.tq-range-input .tq-input-container {
  margin-top: -18px;
  float: right;
}
.tq-inline-container .tq-form-element .tq-range-slider.tq-range-input:after {
  content: '';
  clear: both;
}
.tq-inline-container .tq-hyperlink {
  margin-top: 16px;
  line-height: 27px;
}
.tq-inline-container .tq-badge {
  margin-top: 20px;
  vertical-align: top;
}
.tq-inline-container .tq-button,
.tq-inline-container .tq-btn {
  margin-top: 16px;
  vertical-align: top;
}
.tq-inline-container .tq-options-btn {
  margin-top: 16px;
}
.tq-inline-container .tq-options-btn .tq-dropdown-btn {
  margin-top: 0;
}
.tq-inline-container .tq-label-value-container {
  margin-top: 16px;
}
.tq-inline-container .tq-label-value-container .tq-value-container {
  display: inline-block;
  line-height: 27px;
  width: auto;
}
.tq-inline-container.tq-inline-separator > *:not(.tq-badge) {
  position: relative;
}
.tq-inline-container.tq-inline-separator > *:not(.tq-badge):after {
  position: absolute;
  display: block;
  content: '';
  height: 20px;
  width: 1px;
  top: 50%;
  margin-top: -10px;
}
.tq-inline-container.tq-inline-separator > *:not(.tq-badge):last-child:after {
  display: none;
}
.tq-inline-container.tq-inline-separator.tq-inline-margin-10 > *:not(.tq-badge):after {
  right: -6px;
}
.tq-inline-container.tq-inline-separator.tq-inline-margin-20 > *:not(.tq-badge):after {
  right: -11px;
}
.tq-inline-container.tq-inline-separator > *:not(.tq-badge):after {
  background: #EEEEEE;
}
@media (max-width: 768px) {
  .tq-inline-container.tq-mobile-inline .tq-form-element {
    flex-direction: row;
  }
  .tq-inline-container.tq-mobile-inline .tq-form-element.tq-no-label {
    width: auto !important;
  }
  .tq-inline-container.tq-mobile-inline .tq-form-element.tq-no-label .tq-label-container {
    width: auto !important;
  }
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-label-container,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-output-container {
    width: auto !important;
    padding-right: 20px;
  }
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-number-input,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-datepicker-input,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-file-upload,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-select {
    width: auto !important;
  }
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-radio-group,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-radio-onoff,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-checkbox-group {
    width: auto !important;
  }
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-radio-switch,
  .tq-inline-container.tq-mobile-inline .tq-form-element .tq-checkbox-switch {
    width: auto !important;
    white-space: nowrap;
  }
  .tq-inline-container.tq-mobile-inline .tq-label-value-container .tq-value-container {
    width: auto !important;
  }
  .tq-inline-container .tq-form-element {
    max-height: none !important;
  }
  .tq-inline-container .tq-badge {
    margin-top: 16px;
  }
}
p {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 19px;
}
p:last-child {
  margin: 0;
}
.tq-line-separator {
  height: 1px;
}
.tq-line-separator.tq-hcenter-separator {
  margin: 0 auto;
}
.tq-vertical-separator-wrapper {
  position: relative;
  height: 100%;
  display: flex;
}
.tq-line-separator-vertical {
  width: 1px;
  margin: 0 auto;
}
.tq-line-separator-vertical.tq-vcenter-separator {
  position: absolute;
  top: 50%;
  left: 50%;
}
.tq-shadow-separator {
  overflow: hidden;
  height: 15px;
}
.tq-shadow-separator:after {
  content: '';
  display: block;
  margin: -30px auto 0;
  width: 100%;
  height: 30px;
  border-radius: 15px;
}
.tq-shadow-separator.tq-hcenter-separator {
  margin: 0 auto;
}
.tq-shadow-separator-vertical {
  position: relative;
  display: inline-block;
  width: 15px;
  overflow: hidden;
}
.tq-shadow-separator-vertical.tq-shadow-left {
  left: 50%;
}
.tq-shadow-separator-vertical.tq-shadow-left:after {
  content: '';
  display: block;
  margin-left: -15px;
  width: 15px;
  height: 100%;
  border-radius: 15px;
}
.tq-shadow-separator-vertical.tq-shadow-left.tq-vcenter-separator {
  top: 50%;
}
.tq-shadow-separator-vertical.tq-shadow-right {
  left: calc(50% - 15px);
}
.tq-shadow-separator-vertical.tq-shadow-right:after {
  content: '';
  display: block;
  margin-left: 15px;
  width: 15px;
  height: 100%;
  border-radius: 15px;
}
.tq-shadow-separator-vertical.tq-shadow-right.tq-vcenter-separator {
  top: 50%;
}
/*.tq-accent {
  .tq-line-separator {
    background: fade(@neutral-1, 15%);
  }
    .tq-line-separator-vertical {
    background: fade(@neutral-1, 15%);
  }
  .tq-shadow-separator {
    &:after {
      box-shadow: 0 0 15px fade(@neutral-1, 20%);
    }
  }
  .tq-shadow-separator-vertical {
    &.tq-shadow-left {   
      &:after {     
        box-shadow: 0 0 15px fade(@neutral-1, 20%);
      }
    }
    &.tq-shadow-right {   
      &:after {     
        box-shadow: 0 0 15px fade(@neutral-1, 20%);
      }
    }
  }
}*/
.tq-line-separator {
  background: rgba(219, 219, 219, 0.3);
}
.tq-line-separator-vertical {
  background: rgba(219, 219, 219, 0.3);
}
.tq-shadow-separator:after {
  box-shadow: 0 0 15px rgba(219, 219, 219, 0.4);
}
.tq-shadow-separator-vertical.tq-shadow-left:after {
  box-shadow: 0 0 15px rgba(219, 219, 219, 0.4);
}
.tq-shadow-separator-vertical.tq-shadow-right:after {
  box-shadow: 0 0 15px rgba(219, 219, 219, 0.4);
}
.tq-accent .tq-line-separator {
  background: rgba(56, 63, 78, 0.15);
}
.tq-accent .tq-line-separator-vertical {
  background: rgba(56, 63, 78, 0.15);
}
.tq-accent .tq-shadow-separator:after {
  box-shadow: 0 0 15px rgba(56, 63, 78, 0.2);
}
.tq-accent .tq-shadow-separator-vertical.tq-shadow-left:after {
  box-shadow: 0 0 15px rgba(56, 63, 78, 0.2);
}
.tq-accent .tq-shadow-separator-vertical.tq-shadow-right:after {
  box-shadow: 0 0 15px rgba(56, 63, 78, 0.2);
}
/*.tq-popover-placeholder {
    position: fixed!important;
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    webkit-transition: none !important;
    overflow: hidden;
    z-index: 90;
}
.tq-popover-placeholder.tq-active .tq-drop-down-options,
.tq-popover-placeholder.tq-active .tq-popover,
.tq-popover-placeholder.tq-active .tq-tabs-menu-content,
.tq-popover-placeholder.tq-active .tq-datepicker{
    position: fixed;
}*/
.tq-popover-placeholder {
  overflow: hidden;
  /*  .tq-popover-calculation-size  {
    .tq-drop-down-options,
    .tq-popover,
    .tq-tabs-menu-content,
    .tq-datepicker {
      box-sizing: border-box;
      white-space: nowrap;
    }
  }*/
}
.tq-popover-placeholder .tq-drop-down-options,
.tq-popover-placeholder .tq-popover,
.tq-popover-placeholder .tq-tabs-menu-content,
.tq-popover-placeholder .tq-datepicker {
  position: fixed;
  transition: none !important;
  webkit-transition: none !important;
  z-index: 90;
  -webkit-transform: translateZ(0);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.tq-popover-placeholder .tq-drop-down-options,
.tq-popover-placeholder .tq-popover,
.tq-popover-placeholder .tq-tabs-menu-content {
  max-width: calc(100vw - 17px);
}
.tq-popover-placeholder .tq-hidden {
  display: none;
}
.tq-popover-placeholder .tq-visible {
  display: initial;
  opacity: 1;
  z-index: 91;
}
.tq-popover-placeholder .tq-in-modal {
  z-index: 107;
}
.tq-popover-placeholder .tq-popover {
  opacity: 1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  /*z-index: 90;*/
}
.tq-popover-placeholder .tq-popover:after,
.tq-popover-placeholder .tq-popover:before {
  bottom: -4px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -8px;
  position: absolute;
  width: 0;
}
.tq-popover-placeholder .tq-popover:before {
  bottom: -6px;
}
.tq-popover-placeholder .tq-popover.tq-right-pos:after,
.tq-popover-placeholder .tq-popover.tq-right-pos:before {
  left: -1px;
  top: 50%;
  margin-top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-right-pos:before {
  left: -3px;
}
.tq-popover-placeholder .tq-popover.tq-left-pos:after,
.tq-popover-placeholder .tq-popover.tq-left-pos:before {
  left: auto;
  right: -9px;
  top: 50%;
  margin-top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-left-pos:before {
  right: -11px;
}
.tq-popover-placeholder .tq-popover.tq-bottom-pos:after,
.tq-popover-placeholder .tq-popover.tq-bottom-pos:before {
  left: 50%;
  top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-bottom-pos:before {
  top: -6px;
}
.tq-popover-placeholder .tq-popover.tq-top-left-pos:after,
.tq-popover-placeholder .tq-popover.tq-top-left-pos:before {
  left: auto;
  right: 8px;
}
.tq-popover-placeholder .tq-popover.tq-top-left-pos:before {
  right: 8px;
}
.tq-popover-placeholder .tq-popover.tq-top-right-pos:after,
.tq-popover-placeholder .tq-popover.tq-top-right-pos:before {
  left: 16px;
}
.tq-popover-placeholder .tq-popover.tq-top-right-pos:before {
  left: 16px;
}
.tq-popover-placeholder .tq-popover.tq-bottom-left-pos:after,
.tq-popover-placeholder .tq-popover.tq-bottom-left-pos:before {
  left: auto;
  right: 8px;
  top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-bottom-left-pos:before {
  right: 8px;
  top: -6px;
}
.tq-popover-placeholder .tq-popover.tq-bottom-right-pos:after,
.tq-popover-placeholder .tq-popover.tq-bottom-right-pos:before {
  left: 16px;
  top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-bottom-right-pos:before {
  left: 16px;
  top: -6px;
}
.tq-popover-placeholder .tq-popover.tq-right-bottom-pos:after,
.tq-popover-placeholder .tq-popover.tq-right-bottom-pos:before {
  left: -1px;
  top: 8px;
}
.tq-popover-placeholder .tq-popover.tq-right-bottom-pos:before {
  left: -3px;
  top: 8px;
}
.tq-popover-placeholder .tq-popover.tq-right-top-pos:after,
.tq-popover-placeholder .tq-popover.tq-right-top-pos:before {
  left: -1px;
  top: auto;
  bottom: 8px;
  margin-top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-right-top-pos:before {
  left: -3px;
  bottom: 8px;
  margin-top: -6px;
}
.tq-popover-placeholder .tq-popover.tq-left-bottom-pos:after,
.tq-popover-placeholder .tq-popover.tq-left-bottom-pos:before {
  left: auto;
  right: -9px;
  top: 8px;
  margin-top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-left-bottom-pos:before {
  right: -11px;
  top: 9px;
  margin-top: -5px;
}
.tq-popover-placeholder .tq-popover.tq-left-top-pos:after,
.tq-popover-placeholder .tq-popover.tq-left-top-pos:before {
  left: auto;
  right: -9px;
  top: auto;
  bottom: 8px;
  margin-top: -4px;
}
.tq-popover-placeholder .tq-popover.tq-left-top-pos:before {
  right: -11px;
  bottom: 8px;
  margin-top: -5px;
}
.tq-popover-placeholder .tq-popover.tq-no-pos:after,
.tq-popover-placeholder .tq-popover.tq-no-pos:before {
  content: none;
}
/*.tq-popover-placeholder > * {
    position: fixed!important;
    transition: none !important;
    webkit-transition: none !important;
    overflow: hidden;
    z-index: 90;
}
.tq-popover-placeholder .tq-popover-calculation-size > * {
    box-sizing: border-box;
    white-space: nowrap;
}
.tq-popover-placeholder .tq-hidden{
    display: none;
}
.tq-popover-placeholder .tq-visible{
    display: initial;
}
.tq-popover-placeholder .tq-in-modal{
    z-index: 107;
}*/
/*  POPOVER  */
.tq-popover {
  background: #fff;
  border: 1px solid #EEEEEE;
  color: #383F4E;
  /* RIGHT tooltip position */
  /* LEFT tooltip position */
  /* BOTTOM tooltip position */
  /* TOP-RIGHT tooltip position */
  /* TOP-LEFT tooltip position */
  /* BOTTOM-RIGHT tooltip position */
  /* BOTTOM-LEFT tooltip position */
  /* RIGHT-TOP tooltip position */
  /* RIGHT-BOTTOM tooltip position */
  /* LEFT-TOP tooltip position */
  /* LEFT-BOTTOM tooltip position */
}
.tq-popover:after,
.tq-popover:before {
  border-top: solid #fff 5px;
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
}
.tq-popover:before {
  border-top-color: #EEEEEE;
}
.tq-popover.tq-right-pos:after,
.tq-popover.tq-right-pos:before {
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  border-right: solid #fff 5px;
}
.tq-popover.tq-right-pos:before {
  border-right-color: #EEEEEE;
}
.tq-popover.tq-left-pos:after,
.tq-popover.tq-left-pos:before {
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  border-left: solid #fff 5px;
}
.tq-popover.tq-left-pos:before {
  border-left-color: #EEEEEE;
}
.tq-popover.tq-bottom-pos:after,
.tq-popover.tq-bottom-pos:before {
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-bottom: solid #fff 5px;
  border-top: none;
}
.tq-popover.tq-bottom-pos:before {
  border-bottom-color: #EEEEEE;
}
.tq-popover.tq-bottom-right-pos:after,
.tq-popover.tq-bottom-right-pos:before {
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-bottom: solid #fff 5px;
  border-top: none;
}
.tq-popover.tq-bottom-right-pos:before {
  border-bottom-color: #EEEEEE;
}
.tq-popover.tq-bottom-left-pos:after,
.tq-popover.tq-bottom-left-pos:before {
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-bottom: solid #fff 5px;
  border-top: none;
}
.tq-popover.tq-bottom-left-pos:before {
  border-bottom-color: #EEEEEE;
}
.tq-popover.tq-right-top-pos:after,
.tq-popover.tq-right-top-pos:before {
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  border-right: solid #fff 5px;
  margin-top: -4px;
}
.tq-popover.tq-right-top-pos:before {
  border-right-color: #EEEEEE;
}
.tq-popover.tq-right-bottom-pos:after,
.tq-popover.tq-right-bottom-pos:before {
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  border-right: solid #fff 5px;
}
.tq-popover.tq-right-bottom-pos:before {
  border-right-color: #EEEEEE;
}
.tq-popover.tq-left-top-pos:after,
.tq-popover.tq-left-top-pos:before {
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  border-left: solid #fff 5px;
}
.tq-popover.tq-left-top-pos:before {
  border-left-color: #EEEEEE;
}
.tq-popover.tq-left-bottom-pos:after,
.tq-popover.tq-left-bottom-pos:before {
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  border-left: solid #fff 5px;
}
.tq-popover.tq-left-bottom-pos:before {
  border-left-color: #EEEEEE;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
}
h1.tq-title .tq-text-node {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
h1.tq-title-separator {
  border-bottom: 1px solid #F5F5F5;
}
h1.tq-title-separator .tq-text-node {
  padding-bottom: 20px;
}
h1.tq-title-indents {
  margin-left: 0;
}
h2.tq-title .tq-text-node {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
h2.tq-title-separator {
  border-bottom: 1px solid #F5F5F5;
}
h2.tq-title-separator .tq-text-node {
  padding-bottom: 15px;
}
h2.tq-title-indents {
  margin-left: 16px;
}
h3.tq-title .tq-text-node {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
h3.tq-title-separator {
  border-bottom: 1px solid #F5F5F5;
}
h3.tq-title-separator .tq-text-node {
  padding-bottom: 10px;
}
h3.tq-title-indents {
  margin-left: 32px;
}
h4.tq-title .tq-text-node {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}
h4.tq-title-separator {
  border-bottom: 1px solid #F5F5F5;
}
h4.tq-title-separator .tq-text-node {
  padding-bottom: 10px;
}
h4.tq-title-indents {
  margin-left: 48px;
}
h5.tq-title .tq-text-node {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
h5.tq-title-separator {
  border-bottom: 1px solid #F5F5F5;
}
h5.tq-title-separator .tq-text-node {
  padding-bottom: 5px;
}
h5.tq-title-indents {
  margin-left: 64px;
}
h6.tq-title .tq-text-node {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}
h6.tq-title-separator {
  border-bottom: 1px solid #F5F5F5;
}
h6.tq-title-separator .tq-text-node {
  padding-bottom: 5px;
}
h6.tq-title-indents {
  margin-left: 80px;
}
.tq-bold .tq-text-node {
  font-weight: 700 !important;
}
.tq-title-icon .tq-text-node i {
  margin-right: 5px;
}
.tq-title.tq-title-inline {
  display: inline-block;
}
.tq-title.tq-title-inline + * {
  margin-left: 5px;
}
.tq-tooltip {
  display: block;
  bottom: auto;
  left: auto;
  opacity: 0;
  padding: 8px;
  pointer-events: none;
  position: absolute;
  min-width: 80px;
  text-align: center;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  /*z-index: 91;*/
  /* Fix for tooltip alert cropping of content */
}
.tq-tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}
.tq-tooltip:after {
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  bottom: -5px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -8px;
  position: absolute;
  width: 0;
}
.tq-tooltip.tq-right-pos:after {
  left: -2px;
  top: 50%;
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  margin-top: -4px;
}
.tq-tooltip.tq-left-pos:after {
  left: auto;
  right: -10px;
  top: 50%;
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  margin-top: -4px;
}
.tq-tooltip.tq-bottom-pos:after {
  left: 50%;
  top: -5px;
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-top: none;
}
.tq-tooltip.tq-top-right-pos:after {
  left: auto;
  right: 8px;
}
.tq-tooltip.tq-top-left-pos:after {
  left: 16px;
}
.tq-tooltip.tq-bottom-right-pos:after {
  left: auto;
  right: 8px;
  top: -5px;
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-top: none;
}
.tq-tooltip.tq-bottom-left-pos:after {
  left: 16px;
  top: -5px;
  border-left: solid transparent 5px;
  border-right: solid transparent 5px;
  border-top: none;
}
.tq-tooltip.tq-right-top-pos:after {
  left: -2px;
  top: 8px;
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  margin-top: -4px;
}
.tq-tooltip.tq-right-bottom-pos:after {
  left: -2px;
  top: auto;
  bottom: 8px;
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  margin-top: -4px;
}
.tq-tooltip.tq-left-top-pos:after {
  left: auto;
  right: -10px;
  top: 8px;
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  margin-top: -4px;
}
.tq-tooltip.tq-left-bottom-pos:after {
  left: auto;
  right: -10px;
  top: auto;
  bottom: 8px;
  border-top: solid transparent 5px;
  border-bottom: solid transparent 5px;
  margin-top: -4px;
}
.tq-tooltip .tq-alert .tq-alert-content .tq-fixed-container .tq-hidden-content {
  padding: 0 2px;
}
.tq-tooltip-container {
  display: inline-block;
  position: relative;
  -webkit-transform: translateZ(0);
  /* webkit flicker fix */
  -webkit-font-smoothing: antialiased;
  /* webkit text rendering fix */
  /*margin: 0 6px; Space for icons!!!*/
  /* width: 100%; */
  /* height: 100%; */
}
.tq-tooltip-container:hover .tq-tooltip,
.tq-tooltip-container.tq-visible .tq-tooltip {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.tq-tooltip-visible {
  opacity: 1 !important;
}
.tq-tooltip-alert-compact .tq-alert {
  padding: 8px !important;
}
.tq-tooltip-alert-compact .tq-alert .tq-alert-content {
  margin: 0 !important;
}
.tq-tooltip-alert-compact .tq-alert .tq-alert-content ul {
  padding: 0 !important;
}
.tq-tooltip-alert-compact .tq-alert .tq-alert-content ul li {
  margin: 0 !important;
}
.tq-tooltip-alert-compact .tq-alert .tq-alert-content ul li .tq-paragraph:last-child {
  margin-bottom: 0 !important;
}
.tq-tooltip-alert-compact.tq-tooltip-alert-text-only .tq-fixed-top {
  padding: 0 !important;
}
.tq-tooltip-alert-compact.tq-tooltip-alert-small-icon .tq-alert .tq-alert-icon {
  font-size: 14px;
  line-height: 22px;
}
.tq-tooltip-alert-compact.tq-tooltip-alert-small-icon .tq-alert .tq-alert-content {
  margin: 0 0 0 30px !important;
}
.tq-tooltip {
  background: #383F4E;
  color: #fff;
  font-style: italic;
  font-size: 10px;
  line-height: 12px;
}
.tq-tooltip:after {
  border-top: solid #383F4E 5px;
}
.tq-tooltip.tq-right-pos:after {
  border-right: solid #383F4E 5px;
}
.tq-tooltip.tq-left-pos:after {
  border-left: solid #383F4E 5px;
}
.tq-tooltip.tq-bottom-pos:after {
  border-bottom: solid #383F4E 5px;
}
.tq-tooltip.tq-bottom-right-pos:after {
  border-bottom: solid #383F4E 5px;
}
.tq-tooltip.tq-bottom-left-pos:after {
  border-bottom: solid #383F4E 5px;
}
.tq-tooltip.tq-right-top-pos:after {
  border-right: solid #383F4E 5px;
}
.tq-tooltip.tq-right-bottom-pos:after {
  border-right: solid #383F4E 5px;
}
.tq-tooltip.tq-left-top-pos:after {
  border-left: solid #383F4E 5px;
}
.tq-tooltip.tq-left-bottom-pos:after {
  border-left: solid #383F4E 5px;
}
.tq-tooltip.tq-tooltip-alert {
  padding: 0 !important;
  text-align: left !important;
  color: inherit;
}
.tq-tooltip.tq-tooltip-alert .tq-alert {
  margin-bottom: 0 !important;
  box-shadow: none !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error:after {
  border-top-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-bottom-pos:after {
  border-bottom-color: #d91f26;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-bottom-left-pos:after {
  border-bottom-color: #d91f26;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-bottom-right-pos:after {
  border-bottom-color: #d91f26;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent:after {
  border-top-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-accent.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #f9d5d7 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark:after {
  border-top-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #801216 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #801216 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #801216 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-error.tq-tooltip-alert-dark.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #d91f26 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success:after {
  border-top-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-bottom-pos:after {
  border-bottom-color: #07b24c;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-bottom-left-pos:after {
  border-bottom-color: #07b24c;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-bottom-right-pos:after {
  border-bottom-color: #07b24c;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent:after {
  border-top-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-accent.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #bcfcd6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark:after {
  border-top-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #058137 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #058137 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #058137 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-success.tq-tooltip-alert-dark.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #07b24c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning:after {
  border-top-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-bottom-pos:after {
  border-bottom-color: #c4b71a;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-bottom-left-pos:after {
  border-bottom-color: #c4b71a;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-bottom-right-pos:after {
  border-bottom-color: #c4b71a;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent:after {
  border-top-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-accent.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #f6f2be !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark:after {
  border-top-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #6a630e !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #6a630e !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #6a630e !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-warning.tq-tooltip-alert-dark.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #c4b71a !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info:after {
  border-top-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-bottom-pos:after {
  border-bottom-color: #053d5b;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-bottom-left-pos:after {
  border-bottom-color: #053d5b;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-bottom-right-pos:after {
  border-bottom-color: #053d5b;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent:after {
  border-top-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-accent.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #69c4f6 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark:after {
  border-top-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #000000 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #000000 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #000000 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-info.tq-tooltip-alert-dark.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #053d5b !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral:after {
  border-top-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-bottom-pos:after {
  border-bottom-color: #5c5c5c;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-bottom-left-pos:after {
  border-bottom-color: #5c5c5c;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-bottom-right-pos:after {
  border-bottom-color: #5c5c5c;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: white;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent:after {
  border-top-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-accent.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #d2d2d2 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark:after {
  border-top-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-bottom-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #393939 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-bottom-left-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #393939 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-bottom-right-pos:after {
  border-top-color: transparent !important;
  border-bottom-color: #393939 !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-left-pos:after {
  border-top-color: transparent !important;
  border-left-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-right-pos:after {
  border-top-color: transparent !important;
  border-right-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-left-bottom-pos:after {
  border-top-color: transparent !important;
  border-left-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-right-bottom-pos:after {
  border-top-color: transparent !important;
  border-right-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-left-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-left-color: #5c5c5c !important;
}
.tq-tooltip.tq-tooltip-alert.tq-tooltip-alert-neutral.tq-tooltip-alert-dark.tq-right-top-pos:after {
  top: 12px;
  border-top-color: transparent !important;
  border-right-color: #5c5c5c !important;
}
.tq-btn {
  display: inline-block;
  /*padding: 6px 25px;*/
  padding: 4px 20px;
  border-radius: 30px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  outline: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.tq-btn:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.tq-btn:focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}
.tq-btn.tq-primary:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.tq-btn.tq-primary:focus {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  outline: none;
}
.tq-btn.tq-no-round {
  border-radius: 0 !important;
}
.tq-btn.tq-disabled {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
}
.tq-btn.tq-disabled:hover {
  cursor: default;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
}
.tq-btn.tq-no-background:hover {
  box-shadow: none;
}
.tq-btn.tq-no-background:focus {
  box-shadow: none;
}
.tq-btn.tq-no-padding {
  padding: 0 !important;
}
.tq-btn.tq-icon {
  padding: 4px 15px;
}
.tq-btn.tq-size-s {
  padding: 4px 6px;
  vertical-align: middle;
}
.tq-btn.tq-size-l {
  padding: 15px 15px;
  vertical-align: middle;
}
/*.tq-options-btn {
    position: relative;
    display: inline-flex;
  
    &:hover {
      // Theme
      // border-radius: 30px;
      // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
  
    .tq-btn {
      padding: 4px 10px 4px 15px;
      // Theme
      // border-bottom-right-radius: 0;
      // border-top-right-radius: 0;
  
      &:hover {
        // Theme
        // box-shadow: none;
      }
  
    }
  
    .tq-dropdown-btn {
      position: relative;
      cursor: pointer;
      transition: all .3s ease;
      // Theme
      // background: lighten(@secondary-color-dark-1, 10%);
      // border-bottom-right-radius: 30px;
      // border-left: 1px solid fade(#fff, 30%);
      // border-top-right-radius: 30px;
  
      &:hover {
        // Theme
        // background: lighten(@secondary-color-dark-1, 5%);
        // border-left: 1px solid fade(#fff, 40%);
      }
  
      &.tq-is-clicked {
  
        i.tq-icon-plus {
          transform: translateY(-50%) rotate(45deg);
        }
  
        i.tq-icon-arrow-down,
        i.tq-icon-gear {
          transform: translateY(-50%) rotate(180deg);
          margin-top: -1px;
        }
  
      }
  
      i {
        display: block;
        position: relative;
        padding: 4px 8px;
        top: 50%;
        left: -1px;
        transform: translateY(-50%);
        transition: all .3s ease;
        // Theme
        // line-height: 19px;
        // color: #fff;
      }
  
    }
  
    &.tq-no-round {
  
      &:hover {
        // Theme
        // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
  
      .tq-btn {
        // Theme
        // border-radius: 0;
      }
  
      .tq-dropdown-btn {
        // Theme
        // border-radius: 0;
  
        i {
          left: auto;
        }
  
      }
  
    }
  
    &.tq-dropdown-no-ani {
  
      .tq-dropdown-btn {
  
        &.tq-is-clicked {
  
          i.tq-icon-plus {
            transform: translateY(-50%) rotate(0deg);
          }
  
          i.tq-icon-arrow-down,
          i.tq-icon-gear {
            transform: translateY(-50%) rotate(0deg);
            margin-top: 0;
          }
  
        }
  
      }
  
    }
  
    &.tq-colored-dropdown {
  
      .tq-dropdown-btn {
        // Theme
        // background: @green;
  
        &:hover {
          // Theme
          // background: lighten(@green, 2%);
        }
  
        &.tq-is-clicked {
          // Theme
          // background: @red;
          // border-left: 1px solid fade(#fff, 50%);
  
          &:hover {
            // Theme
            // background: lighten(@red, 8%);
          }
  
        }
  
        i {
          // Theme
          // color: #fff;
        }
  
      }
  
    }
  
    &.tq-options-btn-reversed {
      //flex-direction: row-reverse;
      .tq-btn {
        padding: 4px 15px 4px 10px;
        // Theme
        // border-radius: 30px;
        // border-bottom-left-radius: 0;
        // border-top-left-radius: 0;
      }
      .tq-dropdown-btn {
        // Theme
        // border-radius: 30px;
        // border-top-right-radius: 0;
        // border-bottom-right-radius: 0;
        i {
          left: 0;
        }
      }
      &.tq-no-round {
        .tq-btn {
          // Theme
          // border-radius: 0;
        }
        .tq-dropdown-btn {
          // Theme
          // border-radius: 0;
        }
      }
    }
  }*/
.tq-button {
  display: inline-block;
  width: auto;
  /*padding: 6px 25px;*/
  /* padding: 4px 20px;*/
  padding: 4px 15px;
  border-radius: 30px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  outline: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /*  &.tq-disabled{
    background: none;
    border-color: fade(#000, 30%);
    color: fade(#000, 30%) !important;
    text-shadow: 0 1px 1px fade(#fff, 20%);
    &:hover{
      cursor: default;
      background: none;
      box-shadow: 0 1px 0 fade(#fff, 20%);
    }
  }*/
}
.tq-button + .tq-button {
  margin-left: 1px;
}
.tq-button.tq-block-button {
  width: 100% !important;
}
.tq-button:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.tq-button.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-button-stripped {
  padding: 0 15px !important;
  line-height: 27px;
}
.tq-button.tq-button-stripped:hover {
  box-shadow: none;
}
.tq-button.tq-button-stripped:focus {
  box-shadow: none !important;
}
.tq-button.tq-button-stripped.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-button-border {
  padding: 3px 15px !important;
}
.tq-button.tq-primary:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.tq-button.tq-primary.tq-button-stripped {
  padding: 0 15px !important;
  line-height: 27px;
}
.tq-button.tq-primary.tq-button-stripped:hover {
  box-shadow: none;
}
.tq-button.tq-primary.tq-button-stripped.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-primary.tq-button-border {
  padding: 3px 15px !important;
}
.tq-button.tq-primary.tq-button-border.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-highlighted:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.tq-button.tq-highlighted.tq-button-stripped {
  padding: 0 15px !important;
  line-height: 27px;
}
.tq-button.tq-highlighted.tq-button-stripped:hover {
  box-shadow: none;
}
.tq-button.tq-highlighted.tq-button-stripped.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-highlighted.tq-button-border {
  padding: 3px 15px !important;
}
.tq-button.tq-disabled:hover {
  cursor: not-allowed;
  box-shadow: none;
}
.tq-button.tq-disabled.tq-button-stripped {
  padding: 0 15px !important;
  line-height: 27px;
}
.tq-button.tq-disabled.tq-button-stripped:hover {
  box-shadow: none;
}
.tq-button.tq-disabled.tq-button-stripped.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-disabled.tq-button-border {
  padding: 3px 15px !important;
}
.tq-button.tq-disabled.tq-button-border.tq-no-padding {
  padding: 0 !important;
}
.tq-button.tq-icon {
  padding: 3px 15px;
}
.tq-button.tq-size-s {
  padding: 3px 6px;
}
.tq-button.tq-size-l {
  padding: 15px 15px;
}
.tq-button .tq-loader {
  position: absolute;
  top: 6px;
  right: 11px;
  width: 11px;
  height: 11px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  opacity: 0;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tq-button .tq-feedback:after {
  position: absolute;
  content: "\e91e";
  font-family: "tq-icons";
  font-size: 12px;
  top: 4px;
  right: 14px;
  opacity: 0;
}
.tq-button.tq-feedback-expands-right .tq-loader {
  position: relative;
  left: auto;
  top: 2px;
  right: 0;
  display: inline-block;
}
.tq-button.tq-feedback-expands-right .tq-feedback:after {
  position: relative;
  line-height: 1 !important;
  right: 0;
}
.tq-button.tq-feedback-left .tq-loader {
  right: auto;
  left: 6px;
}
.tq-button.tq-feedback-left .tq-feedback:after {
  right: auto;
  left: 12px;
}
.tq-button.tq-loading {
  position: relative;
}
.tq-button.tq-loading .tq-loader {
  opacity: 1;
}
.tq-button.tq-loading i {
  opacity: 0;
}
.tq-button.tq-error,
.tq-button.tq-success {
  position: relative;
}
.tq-button.tq-error .tq-loader,
.tq-button.tq-success .tq-loader {
  opacity: 0;
}
.tq-button.tq-error .tq-feedback:after,
.tq-button.tq-success .tq-feedback:after {
  opacity: 1;
}
.tq-button.tq-error i,
.tq-button.tq-success i {
  opacity: 0;
}
.tq-button.tq-error.tq-button-stripped {
  background: transparent !important;
  padding: 0 15px !important;
  line-height: 27px;
}
.tq-button.tq-error.tq-button-stripped:hover {
  box-shadow: none;
}
.tq-button.tq-error .tq-feedback:after {
  content: "\e931" !important;
}
.tq-button-progress {
  position: relative;
  overflow: hidden;
}
.tq-button-progress > * {
  position: relative;
  z-index: 1;
}
.tq-button-progress:not(.tq-button-progress-icon) > i {
  display: none;
}
.tq-button-progress:not(.tq-button-progress-icon).tq-button-progress-loading.tq-button-progress-spinner {
  padding-right: 30px;
}
.tq-button-progress:not(.tq-button-progress-icon).tq-success,
.tq-button-progress:not(.tq-button-progress-icon).tq-error {
  padding-right: 30px;
}
.tq-button-progress:not(.tq-button-progress-icon) .tq-feedback,
.tq-button-progress:not(.tq-button-progress-icon) .tq-spinner {
  right: 12px;
}
.tq-button-progress.tq-button-progress-loading.tq-button-progress-bg .tq-bg-loader {
  opacity: 1;
}
.tq-button-progress.tq-button-progress-loading.tq-button-progress-spinner .tq-spinner::after {
  opacity: 1;
}
.tq-button-progress.tq-button-progress-loading.tq-button-progress-spinner > i {
  opacity: 0;
}
.tq-button-progress.tq-button-progress-bg .tq-bg-loader {
  z-index: 0;
  width: 50%;
  height: 100%;
  left: -50%;
  top: 0;
  position: absolute;
  opacity: 0;
}
.tq-button-progress .tq-spinner {
  height: 100%;
  width: auto;
  position: absolute;
  right: auto;
  left: auto;
}
.tq-button-progress .tq-spinner::after {
  content: "\eb1d";
  right: 0 !important;
  top: 4px !important;
  position: absolute;
  opacity: 0;
}
.tq-button-progress > i {
  display: inline-block;
  opacity: 1;
}
.tq-button-progress .tq-feedback {
  height: 100%;
  position: absolute;
  top: 0;
}
.tq-button-progress .tq-feedback::after {
  right: 0 !important;
  top: 50% !important;
}
.tq-button-progress.tq-success .tq-bg-loader,
.tq-button-progress.tq-error .tq-bg-loader {
  opacity: 0;
}
.tq-button-progress.tq-success .tq-spinner::after,
.tq-button-progress.tq-error .tq-spinner::after {
  opacity: 0;
}
.tq-button-progress.tq-success > i,
.tq-button-progress.tq-error > i {
  opacity: 0;
}
.tq-options-btn {
  display: inline-block;
  width: auto;
  position: relative;
  margin-right: 2px;
}
.tq-options-btn + .tq-options-btn {
  margin-left: 2px;
}
.tq-options-btn.tq-block-button {
  width: 100% !important;
}
.tq-options-btn.tq-block-button .tq-options-btn-container {
  width: 100% !important;
}
.tq-options-btn.tq-block-button .tq-options-btn-container .tq-button {
  width: calc(100% - 29px);
}
.tq-options-btn .tq-options-btn-container {
  position: relative;
  display: inline-flex;
  transition: all 0.3s ease-in-out;
  width: auto;
  /*    .tq-drop-down-container {
      .tq-drop-down-options {
        right: 0;
      }
    }*/
}
.tq-options-btn .tq-options-btn-container .tq-button {
  padding: 3px 10px 3px 15px;
  margin: 0;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 27px;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-is-clicked i.tq-icon-plus {
  transform: translateY(-50%) rotate(45deg);
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-is-clicked i.tq-icon-arrow-down,
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-is-clicked i.tq-icon-gear {
  transform: translateY(-50%) rotate(180deg);
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn i {
  display: block;
  position: relative;
  padding: 3px 8px;
  top: 50%;
  left: -1px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-has-feedback i {
  opacity: 0;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-has-feedback .tq-loader {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -7px;
  margin-left: -7px;
  border-radius: 50%;
}
.tq-options-btn.tq-options-button-border .tq-button {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.tq-options-btn.tq-options-button-stripped .tq-button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 29px;
}
.tq-options-btn.tq-disabled .tq-options-btn-container .tq-button:hover {
  cursor: default;
}
.tq-options-btn.tq-disabled .tq-options-btn-container .tq-dropdown-btn:hover {
  cursor: default;
}
.tq-options-btn.tq-disabled:hover {
  cursor: not-allowed !important;
}
.tq-options-btn.tq-disabled:hover .tq-button {
  cursor: not-allowed !important;
}
.tq-options-btn.tq-disabled:hover .tq-dropdown-btn {
  cursor: not-allowed !important;
}
.tq-options-btn.tq-dropdown-no-ani .tq-options-btn-container .tq-dropdown-btn.tq-is-clicked i.tq-icon-plus {
  transform: translateY(-50%) rotate(0deg);
}
.tq-options-btn.tq-dropdown-no-ani .tq-options-btn-container .tq-dropdown-btn.tq-is-clicked i.tq-icon-arrow-down,
.tq-options-btn.tq-dropdown-no-ani .tq-options-btn-container .tq-dropdown-btn.tq-is-clicked i.tq-icon-gear {
  transform: translateY(-50%) rotate(0deg);
  margin-top: 0;
}
.tq-options-btn.tq-options-btn-reversed .tq-options-btn-container .tq-button {
  padding: 4px 15px 4px 10px;
  order: 2;
}
.tq-options-btn.tq-options-btn-reversed .tq-options-btn-container .tq-dropdown-btn {
  order: 1;
}
.tq-options-btn.tq-options-btn-reversed .tq-options-btn-container .tq-dropdown-btn i {
  left: 0;
}
.tq-options-btn .tq-drop-down-container {
  position: absolute;
  width: 100%;
}
.tq-options-btn .tq-drop-down-container .tq-drop-down-options {
  right: 0;
  min-width: 80px;
  max-width: 300px !important;
  width: auto;
  position: absolute;
}
.tq-options-btn .tq-drop-down-container.tq-drop-down-left .tq-drop-down-options {
  right: auto;
  left: 0;
  width: auto;
  min-width: 80px;
  max-width: 300px;
  position: absolute;
}
.tq-button-drop-down {
  display: block;
  position: absolute;
  max-width: 220px;
  height: auto;
  max-height: 121px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999;
}
.tq-button-drop-down .tq-list-new.tq-borders > li:last-child {
  border-bottom: none !important;
}
.tq-button-drop-down .tq-list-new.tq-borders > li .tq-item {
  padding: 4px 10px;
}
.tq-button-message .tq-tooltip-alert {
  position: absolute;
}
.tq-btn {
  background-color: #10C891;
  border: 1px solid #10C891;
  text-decoration: none;
  color: #fff;
}
.tq-btn:hover {
  background: #ff9448;
  border-color: #ff9448;
}
.tq-btn:focus {
  background: #ff9448;
  border-color: #ff9448;
}
.tq-btn:active {
  background: #ffa96c;
  border-color: #ffa96c;
}
.tq-btn.tq-primary {
  background: transparent;
  border-color: #ffa96c;
  color: #ffa96c;
}
.tq-btn.tq-primary:active {
  background: #ffa96c;
  border-color: #ffa96c;
}
.tq-btn.tq-disabled {
  background: none;
  border-color: rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.3) !important;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.2);
}
.tq-btn.tq-disabled:hover {
  cursor: default;
  background: none;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
}
.tq-btn.tq-no-background {
  background: none;
  border-color: transparent;
  color: #164194;
}
.tq-btn.tq-no-background:hover {
  background: none;
  border-color: transparent;
  color: #10C891 !important;
}
.tq-btn.tq-no-background:focus {
  background: none;
  border-color: transparent;
  color: #10C891 !important;
}
.tq-btn.tq-size-s {
  line-height: 14px;
  font-size: 10px;
}
.tq-btn.tq-size-l {
  line-height: 1px;
  font-size: 24px;
}
.tq-dark:not(.tq-light) .tq-btn.tq-no-background {
  color: #85a5d6;
}
.tq-dark:not(.tq-light) .tq-btn.tq-no-background:hover {
  color: #fff;
}
.tq-dark:not(.tq-light) .tq-btn.tq-no-background:focus {
  outline: none;
  color: #fff;
}
/*.tq-options-btn {

  &:hover {
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .tq-btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &:hover {
      box-shadow: none;
    }
  }

  .tq-dropdown-btn {
    background: lighten(@secondary-color-dark-1, 10%);
    border-bottom-right-radius: 30px;
    border-left: 1px solid fade(#fff, 30%);
    border-top-right-radius: 30px;

    &:hover {
      background: lighten(@secondary-color-dark-1, 5%);
      border-left: 1px solid fade(#fff, 40%);
    }

    i {
      line-height: 19px;
      color: #fff;
    }

  }

  &.tq-no-round {

    &:hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .tq-btn {
      border-radius: 0;
    }

    .tq-dropdown-btn {
      border-radius: 0;
    }

  }

  &.tq-colored-dropdown {

    .tq-dropdown-btn {
      background: @green;

      &:hover {
        background: lighten(@green, 2%);
      }

      &.tq-is-clicked {
        background: @red;
        border-left: 1px solid fade(#fff, 50%);

        &:hover {
          background: lighten(@red, 8%);
        }

      }

      i {
        color: #fff;
      }

    }

  }

  &.tq-options-btn-reversed {

    .tq-btn {
      border-radius: 30px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    .tq-dropdown-btn {
      border-radius: 30px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.tq-no-round {

      .tq-btn {
        border-radius: 0;
      }

      .tq-dropdown-btn {
        border-radius: 0;
      }

    }

  }

}*/
.tq-button {
  background-color: #F01A1A;
  border: none;
  text-decoration: none;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}
.tq-button:focus {
  outline: none !important;
}
.tq-button:hover,
.tq-button:focus {
  background: #d80e0e;
  border-color: #d80e0e;
}
.tq-button:active {
  background: #d80e0e;
  border-color: #d80e0e;
}
.tq-button.tq-button-stripped {
  background: transparent;
  color: #F01A1A;
}
.tq-button.tq-button-stripped:hover {
  color: #b20c0c;
}
.tq-button.tq-button-stripped .tq-loader {
  border-radius: 50%;
  border: 2px solid #F01A1A;
  border-top: 2px solid rgba(240, 26, 26, 0.5);
}
.tq-button.tq-button-stripped .tq-feedback:after {
  color: #F01A1A;
}
.tq-button.tq-button-border {
  background: transparent;
  color: #F01A1A;
  border: 1px solid #F01A1A;
}
.tq-button.tq-button-border:hover,
.tq-button.tq-button-border:focus {
  color: #b20c0c !important;
  border: 1px solid #b20c0c;
}
.tq-button.tq-button-border .tq-loader {
  border-radius: 50%;
  border: 2px solid #F01A1A;
  border-top: 2px solid rgba(240, 26, 26, 0.5);
}
.tq-button.tq-button-border .tq-feedback:after {
  color: #F01A1A;
}
.tq-button.tq-primary {
  background: #10C891;
  border-color: #10C891;
}
.tq-button.tq-primary:hover,
.tq-button.tq-primary:focus {
  background: #0da779;
  border-color: #0da779;
}
.tq-button.tq-primary:active {
  background: #0da779;
  border-color: #0da779;
}
.tq-button.tq-primary.tq-button-stripped {
  background: transparent !important;
  color: #10C891 !important;
}
.tq-button.tq-primary.tq-button-stripped:hover {
  color: #086a4d !important;
}
.tq-button.tq-primary.tq-button-stripped .tq-loader {
  border-radius: 50%;
  border: 2px solid #10C891;
  border-top: 2px solid rgba(16, 200, 145, 0.5);
}
.tq-button.tq-primary.tq-button-stripped .tq-feedback:after {
  color: #10C891;
}
.tq-button.tq-primary.tq-button-border {
  background: transparent !important;
  color: #10C891 !important;
  border: 1px solid #10C891;
}
.tq-button.tq-primary.tq-button-border:hover {
  color: #086a4d !important;
  border: 1px solid #086a4d;
}
.tq-button.tq-primary.tq-button-border .tq-loader {
  border-radius: 50%;
  border: 2px solid #10C891;
  border-top: 2px solid rgba(16, 200, 145, 0.5);
}
.tq-button.tq-primary.tq-button-border .tq-feedback:after {
  color: #10C891;
}
.tq-button.tq-primary.tq-button-border {
  background: transparent;
  color: #10C891;
  border: 1px solid #10C891;
}
.tq-button.tq-primary.tq-button-border:hover {
  color: #0a815e !important;
  border: 1px solid #0a815e;
}
.tq-button.tq-highlighted {
  background: #164194;
  border-color: #164194;
}
.tq-button.tq-highlighted:active {
  background: #113375;
  border-color: #113375;
}
.tq-button.tq-highlighted:hover {
  background: #113375;
  border-color: #113375;
}
.tq-button.tq-highlighted.tq-button-stripped {
  background: transparent !important;
  color: #164194 !important;
}
.tq-button.tq-highlighted.tq-button-stripped:hover {
  color: #091a3b !important;
}
.tq-button.tq-highlighted.tq-button-stripped .tq-loader {
  border-radius: 50%;
  border: 2px solid #164194;
  border-top: 2px solid rgba(22, 65, 148, 0.5);
}
.tq-button.tq-highlighted.tq-button-stripped .tq-feedback:after {
  color: #164194;
}
.tq-button.tq-highlighted.tq-button-border {
  background: transparent;
  color: #164194;
  border: 1px solid #164194;
}
.tq-button.tq-highlighted.tq-button-border:hover {
  color: #0c2451 !important;
  border: 1px solid #0c2451;
}
.tq-button.tq-highlighted.tq-button-border .tq-loader {
  border-radius: 50%;
  border: 2px solid #164194;
  border-top: 2px solid rgba(22, 65, 148, 0.5);
}
.tq-button.tq-highlighted.tq-button-border .tq-feedback:after {
  color: #164194;
}
.tq-button.tq-disabled {
  background: #ffffff;
  border-color: #ffffff;
  color: #fff;
}
.tq-button.tq-disabled:hover {
  background: #ffffff;
  border-color: #ffffff;
}
.tq-button.tq-disabled.tq-button-stripped {
  background: transparent !important;
  color: #ffffff !important;
}
.tq-button.tq-disabled.tq-button-stripped:hover {
  color: #ffffff !important;
}
.tq-button.tq-disabled.tq-button-stripped .tq-loader {
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
}
.tq-button.tq-disabled.tq-button-stripped .tq-feedback:after {
  color: #ffffff;
}
.tq-button.tq-disabled.tq-button-border {
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.tq-button.tq-disabled.tq-button-border:hover {
  color: #ffffff !important;
}
.tq-button.tq-disabled.tq-button-border .tq-loader {
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
}
.tq-button.tq-disabled.tq-button-border .tq-feedback:after {
  color: #ffffff;
}
.tq-button.tq-size-s {
  line-height: 6.8px;
  font-size: 10px;
}
.tq-button.tq-size-l {
  line-height: 16px;
  font-size: 16px;
}
.tq-button .tq-loader {
  border-radius: 50%;
  border: 2px solid #fff;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
}
.tq-button .tq-feedback:after {
  color: #fff;
}
.tq-button.tq-error {
  background: #d91f26;
  color: #fff;
}
.tq-button.tq-error .tq-feedback:after {
  top: 0;
  line-height: 29px;
  color: #fff;
}
.tq-button.tq-error:hover {
  color: #fff;
  background: #e5464c;
}
.tq-button.tq-error.tq-button-border {
  background: transparent !important;
  border: 1px solid #d91f26;
  color: #d91f26 !important;
}
.tq-button.tq-error.tq-button-border .tq-feedback:after {
  top: 0;
  line-height: 27px;
  color: #d91f26;
}
.tq-button.tq-error.tq-button-border:hover {
  border: 1px solid #d91f26;
}
.tq-button.tq-error.tq-button-stripped {
  background: transparent !important;
  color: #d91f26;
}
.tq-button.tq-error.tq-button-stripped .tq-feedback:after {
  top: 0;
  line-height: 29px;
  color: #d91f26;
}
.tq-button.tq-error.tq-button-stripped:hover {
  color: #96151a;
}
.tq-button.tq-success {
  background: #07b24c;
  color: #fff;
}
.tq-button.tq-success .tq-feedback:after {
  top: 0;
  line-height: 29px;
  color: #fff;
}
.tq-button.tq-success:hover {
  color: #fff;
  background: #058137;
}
.tq-button.tq-success.tq-button-border {
  background: transparent !important;
  border: 1px solid #07b24c;
  color: #07b24c !important;
}
.tq-button.tq-success.tq-button-border .tq-feedback:after {
  top: 0;
  line-height: 27px;
  color: #07b24c;
}
.tq-button.tq-success.tq-button-border:hover {
  border: 1px solid #07b24c;
}
.tq-button.tq-success.tq-button-stripped {
  background: transparent !important;
  color: #07b24c;
}
.tq-button.tq-success.tq-button-stripped .tq-feedback:after {
  top: 0;
  line-height: 29px;
  color: #07b24c;
}
.tq-button.tq-success.tq-button-stripped:hover {
  color: #04682d;
}
.tq-button-progress.tq-button-progress-loading.tq-button-progress-bg .tq-bg-loader {
  animation-play-state: running;
}
.tq-button-progress.tq-button-progress-loading.tq-button-progress-spinner .tq-spinner::after {
  transition-delay: 0.15s;
}
.tq-button-progress.tq-button-progress-loading.tq-button-progress-spinner > i {
  transform: scale(0);
}
.tq-button-progress.tq-button-progress-bg .tq-bg-loader {
  background: #9a0a0a;
  transition: 0.3s ease-out;
  animation-name: leftToRight;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-play-state: paused;
}
.tq-button-progress.tq-button-progress-bg.tq-primary .tq-bg-loader {
  background: #086a4d;
}
.tq-button-progress.tq-button-progress-bg.tq-highlighted .tq-bg-loader {
  background: #091a3b;
}
.tq-button-progress .tq-spinner::after {
  font-family: 'tq-icons';
  font-size: 12px;
  transition: all 0.15s ease-in-out;
  animation: spin 2s linear infinite;
  line-height: 1 !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tq-button-progress > i {
  transform: scale(1);
  transition: all 0.15s ease-out;
}
.tq-button-progress .tq-feedback::after {
  line-height: 1 !important;
  transform: translateY(-50%) scale(0);
  transition: all 0.15s ease-in;
  transition-delay: 0.1s;
}
.tq-button-progress.tq-success .tq-bg-loader,
.tq-button-progress.tq-error .tq-bg-loader {
  animation-play-state: paused;
}
.tq-button-progress.tq-success .tq-feedback::after,
.tq-button-progress.tq-error .tq-feedback::after {
  transform: translateY(-50%) scale(1);
}
.tq-button-progress.tq-success > i,
.tq-button-progress.tq-error > i {
  transform: scale(0);
}
@keyframes leftToRight {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
.tq-accent .tq-button.tq-button-border {
  color: #f67a7a !important;
  border: 1px solid #f67a7a;
  background: rgba(240, 26, 26, 0.2) !important;
}
.tq-accent .tq-button.tq-button-border:hover {
  color: #fff !important;
  border: 1px solid #fff;
}
.tq-accent .tq-button.tq-button-stripped {
  background: transparent;
  color: #f67a7a;
}
.tq-accent .tq-button.tq-button-stripped:hover {
  color: #fff;
}
.tq-accent .tq-button.tq-primary.tq-button-border {
  color: #4df1c0 !important;
  border: 1px solid #4df1c0;
  background: rgba(16, 200, 145, 0.2) !important;
}
.tq-accent .tq-button.tq-primary.tq-button-border:hover {
  color: #fff !important;
  border: 1px solid #fff;
}
.tq-accent .tq-button.tq-primary.tq-button-stripped {
  background: transparent;
  color: #4df1c0 !important;
}
.tq-accent .tq-button.tq-primary.tq-button-stripped:hover {
  color: #fff !important;
}
.tq-accent .tq-button.tq-highlighted.tq-button-border {
  color: #306ce0 !important;
  border: 1px solid #306ce0;
  background: rgba(22, 65, 148, 0.2) !important;
}
.tq-accent .tq-button.tq-highlighted.tq-button-border:hover {
  color: #fff !important;
  border: 1px solid #fff;
}
.tq-accent .tq-button.tq-highlighted.tq-button-stripped {
  background: transparent;
  color: #306ce0 !important;
}
.tq-accent .tq-button.tq-highlighted.tq-button-stripped:hover {
  color: #fff !important;
}
.tq-accent .tq-button.tq-disabled.tq-button-border {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.2) !important;
}
.tq-accent .tq-button.tq-disabled.tq-button-border:hover {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.2) !important;
}
.tq-options-btn:focus {
  outline: none !important;
}
.tq-options-btn .tq-options-btn-container:hover {
  border-radius: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.tq-options-btn .tq-options-btn-container .tq-button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.tq-options-btn .tq-options-btn-container .tq-button:hover {
  box-shadow: none;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn {
  background: #ca0d0d;
  border-bottom-right-radius: 30px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top-right-radius: 30px;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn:hover {
  background: #b20c0c;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #fff;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn .tq-options-btn-container .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #fff;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-options-button-border .tq-button {
  color: #9a0a0a;
  border: 1px solid #F01A1A;
  border-right: none;
  background: transparent;
}
.tq-options-btn.tq-options-button-border .tq-button:hover {
  color: #820909;
  border: 1px solid #d80e0e;
  border-right: none;
}
.tq-options-btn.tq-options-button-border.tq-options-btn-reversed .tq-button {
  border-right: 1px solid #F01A1A !important;
  border-left: none !important;
}
.tq-options-btn.tq-options-button-border .tq-dropdown-btn {
  background: rgba(240, 26, 26, 0.5);
  border-bottom-right-radius: 30px;
  border: 1px solid #F01A1A;
  border-top-right-radius: 30px;
}
.tq-options-btn.tq-options-button-border .tq-dropdown-btn:hover {
  background: rgba(240, 26, 26, 0.7);
  border: 1px solid #F01A1A;
}
.tq-options-btn.tq-options-button-border .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #9a0a0a;
}
.tq-options-btn.tq-options-button-border .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-options-button-border .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #9a0a0a;
  border-top: 2px solid rgba(154, 10, 10, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-options-button-stripped .tq-options-btn-container:hover {
  border-radius: 0;
  box-shadow: none;
}
.tq-options-btn.tq-options-button-stripped .tq-button {
  color: #9a0a0a;
  border: none;
  border-radius: 0 !important;
  background: transparent;
}
.tq-options-btn.tq-options-button-stripped .tq-button:hover {
  color: #820909;
  border: none;
  border-radius: none !important;
  box-shadow: none !important;
}
.tq-options-btn.tq-options-button-stripped .tq-dropdown-btn {
  background: rgba(240, 26, 26, 0.5);
  border-radius: 0 !important;
  border: none;
}
.tq-options-btn.tq-options-button-stripped .tq-dropdown-btn:hover {
  background: rgba(240, 26, 26, 0.7);
  border: none !important;
}
.tq-options-btn.tq-options-button-stripped .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #9a0a0a;
}
.tq-options-btn.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #9a0a0a;
  border-top: 2px solid rgba(154, 10, 10, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-options-button-stripped:hover {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.tq-options-btn.tq-primary .tq-options-btn-container .tq-button {
  background: #10C891;
  border-color: #10C891 !important;
}
.tq-options-btn.tq-primary .tq-options-btn-container .tq-button:hover {
  background: #0da779;
  border-color: #0da779 !important;
}
.tq-options-btn.tq-primary .tq-options-btn-container .tq-dropdown-btn {
  background: #0c996f;
}
.tq-options-btn.tq-primary .tq-options-btn-container .tq-dropdown-btn:hover {
  background: #0a815e;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-button {
  color: #086a4d;
  border: 1px solid #10C891;
  border-right: none;
  background: transparent;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-button:hover {
  color: #07523b;
  border: 1px solid #0da779;
  border-right: none;
  background: transparent !important;
}
.tq-options-btn.tq-primary.tq-options-button-border.tq-options-btn-reversed .tq-button {
  border-right: 1px solid #10C891 !important;
  border-left: none !important;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-dropdown-btn {
  background: rgba(16, 200, 145, 0.5);
  border-bottom-right-radius: 30px;
  border: 1px solid #10C891;
  border-top-right-radius: 30px;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-dropdown-btn:hover {
  background: rgba(16, 200, 145, 0.7);
  border: 1px solid #10C891;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #086a4d;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-primary.tq-options-button-border .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #086a4d;
  border-top: 2px solid rgba(8, 106, 77, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-options-btn-container:hover {
  border-radius: 0;
  box-shadow: none;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-button {
  color: #086a4d;
  border: none;
  border-radius: 0 !important;
  background: transparent;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-button:hover {
  color: #07523b;
  border: none;
  border-radius: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-dropdown-btn {
  background: rgba(16, 200, 145, 0.5);
  border-radius: 0 !important;
  border: none;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-dropdown-btn:hover {
  background: rgba(16, 200, 145, 0.7);
  border: none !important;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #086a4d;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-primary.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #086a4d;
  border-top: 2px solid rgba(8, 106, 77, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-primary.tq-options-button-stripped:hover {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.tq-options-btn.tq-highlighted .tq-options-btn-container .tq-button {
  background: #164194;
  border-color: #164194 !important;
}
.tq-options-btn.tq-highlighted .tq-options-btn-container .tq-button:hover {
  background: #113375;
  border-color: #113375;
}
.tq-options-btn.tq-highlighted .tq-options-btn-container .tq-dropdown-btn {
  background: #0f2d68;
}
.tq-options-btn.tq-highlighted .tq-options-btn-container .tq-dropdown-btn:hover {
  background: #0c2451;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-button {
  color: #091a3b;
  border: 1px solid #164194;
  border-right: none;
  background: transparent;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-button:hover {
  color: #061025;
  border: 1px solid #113375;
  border-right: none;
  background: transparent !important;
}
.tq-options-btn.tq-highlighted.tq-options-button-border.tq-options-btn-reversed .tq-button {
  border-right: 1px solid #164194 !important;
  border-left: none !important;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-dropdown-btn {
  background: rgba(22, 65, 148, 0.5);
  border-bottom-right-radius: 30px;
  border: 1px solid #164194;
  border-top-right-radius: 30px;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-dropdown-btn:hover {
  background: rgba(22, 65, 148, 0.7);
  border: 1px solid #164194;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #091a3b;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-highlighted.tq-options-button-border .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #091a3b;
  border-top: 2px solid rgba(9, 26, 59, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-options-btn-container:hover {
  border-radius: 0;
  box-shadow: none;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-button {
  color: #091a3b;
  border: none;
  border-radius: 0 !important;
  background: transparent;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-button:hover {
  color: #061025;
  border: none;
  border-radius: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-dropdown-btn {
  background: rgba(22, 65, 148, 0.5);
  border-radius: 0 !important;
  border: none;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-dropdown-btn:hover {
  background: rgba(22, 65, 148, 0.7);
  border: none !important;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #091a3b;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #091a3b;
  border-top: 2px solid rgba(9, 26, 59, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-highlighted.tq-options-button-stripped:hover {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.tq-options-btn.tq-disabled .tq-options-btn-container:hover {
  box-shadow: none !important;
}
.tq-options-btn.tq-disabled .tq-options-btn-container .tq-button {
  background: #ffffff;
  border-color: #ffffff !important;
}
.tq-options-btn.tq-disabled .tq-options-btn-container .tq-button:hover {
  background: #ffffff;
  border-color: #ffffff;
}
.tq-options-btn.tq-disabled .tq-options-btn-container .tq-dropdown-btn {
  background: #e6e6e6;
}
.tq-options-btn.tq-disabled .tq-options-btn-container .tq-dropdown-btn:hover {
  background: #e6e6e6;
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-button {
  color: #cccccc;
  border: 1px solid #ffffff;
  border-right: none;
  background: transparent;
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-button:hover {
  color: #bfbfbf;
  border: 1px solid #ededed;
  border-right: none;
  background: transparent !important;
}
.tq-options-btn.tq-disabled.tq-options-button-border.tq-options-btn-reversed .tq-button {
  border-right: 1px solid #ffffff !important;
  border-left: none !important;
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-dropdown-btn {
  background: rgba(255, 255, 255, 0.5);
  border-bottom-right-radius: 30px;
  border: 1px solid #ffffff;
  border-top-right-radius: 30px;
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-dropdown-btn:hover {
  border: 1px solid #ffffff;
  background: rgba(255, 255, 255, 0.5);
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #cccccc;
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-disabled.tq-options-button-border .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #cccccc;
  border-top: 2px solid rgba(204, 204, 204, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-options-btn-container:hover {
  border-radius: 0;
  box-shadow: none;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-button {
  color: #cccccc;
  border: none;
  border-radius: 0 !important;
  background: transparent;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-button:hover {
  color: #bfbfbf;
  border: none;
  border-radius: none !important;
  box-shadow: none !important;
  background: transparent !important;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-dropdown-btn {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0 !important;
  border: none;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-dropdown-btn:hover {
  background: rgba(255, 255, 255, 0.5);
  border: none !important;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-dropdown-btn i {
  line-height: 12.8px;
  color: #cccccc;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback {
  width: 28px;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped .tq-dropdown-btn.tq-has-feedback .tq-loader {
  border: 2px solid #cccccc;
  border-top: 2px solid rgba(204, 204, 204, 0.5);
  animation: spin 2s linear infinite;
}
.tq-options-btn.tq-disabled.tq-options-button-stripped:hover {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.tq-options-btn.tq-options-btn-reversed .tq-options-btn-container .tq-button {
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.tq-options-btn.tq-options-btn-reversed .tq-options-btn-container .tq-dropdown-btn {
  border-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.tq-options-btn.tq-ddwn-fl-top .tq-dropdown-btn {
  position: relative;
}
.tq-options-btn.tq-ddwn-fl-top .tq-dropdown-btn .tq-dropdown-caret {
  display: block;
  position: absolute;
  width: 11px;
  height: 7px;
  z-index: 100000;
  top: 30px;
  left: 50%;
  margin-left: -5.5px;
}
.tq-options-btn.tq-ddwn-fl-top .tq-dropdown-btn .tq-dropdown-caret:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5.5px 7px 5.5px;
  border-color: transparent transparent #c7cad2 transparent;
}
.tq-options-btn.tq-ddwn-fl-top .tq-dropdown-btn .tq-dropdown-caret:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4.5px 6px 4.5px;
  border-color: transparent transparent #fff transparent;
}
.tq-options-btn.tq-ddwn-fl-bottom .tq-dropdown-btn .tq-dropdown-caret {
  display: block;
  position: absolute;
  width: 11px;
  height: 7px;
  z-index: 100000;
  transform: rotate(180deg);
  top: -9px;
  left: 50%;
  margin-left: -5.5px;
}
.tq-options-btn.tq-ddwn-fl-bottom .tq-dropdown-btn .tq-dropdown-caret:before {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5.5px 7px 5.5px;
  border-color: transparent transparent #c7cad2 transparent;
}
.tq-options-btn.tq-ddwn-fl-bottom .tq-dropdown-btn .tq-dropdown-caret:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4.5px 6px 4.5px;
  border-color: transparent transparent #fff transparent;
}
.tq-button-drop-down {
  background: #fff;
  border: 1px solid #ffffff;
  box-shadow: 1px 0 3px #ffffff;
}
.tq-button-drop-down .tq-list-new.tq-borders > li:last-child {
  border-bottom: none !important;
}
.tq-button-drop-down .tq-list-new.tq-borders > li .tq-item {
  padding: 5px 10px;
}
.tq-button-drop-down.tq-round {
  border-radius: 8px;
}
.tq-button-rectangle {
  border-radius: 0 !important;
}
.tq-options-btn.tq-options-button-rectangle {
  border-radius: 0 !important;
}
.tq-options-btn.tq-options-button-rectangle .tq-options-btn-container {
  border-radius: 0 !important;
}
.tq-options-btn.tq-options-button-rectangle .tq-options-btn-container .tq-button {
  border-radius: 0 !important;
}
.tq-options-btn.tq-options-button-rectangle .tq-options-btn-container .tq-dropdown-btn {
  border-radius: 0 !important;
}
.tq-ddwn-bl.tq-options-btn {
  border-bottom-left-radius: 0 !important;
}
.tq-ddwn-bl.tq-options-btn .tq-options-btn-container {
  border-bottom-left-radius: 0 !important;
}
.tq-ddwn-bl:not(.tq-options-btn-reversed) .tq-button {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 15px !important;
}
.tq-ddwn-bl.tq-options-btn-reversed .tq-dropdown-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 15px !important;
}
.tq-ddwn-bl .tq-button-drop-down.tq-round {
  border-top-left-radius: 0 !important;
}
.tq-ddwn-br.tq-options-btn {
  border-top-right-radius: 0 !important;
}
.tq-ddwn-br.tq-options-btn .tq-options-btn-container {
  border-top-right-radius: 0 !important;
}
.tq-ddwn-br.tq-options-btn-reversed .tq-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 15px !important;
}
.tq-ddwn-br:not(.tq-options-btn-reversed) .tq-dropdown-btn {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 15px !important;
}
.tq-ddwn-br .tq-button-drop-down.tq-round {
  border-bottom-right-radius: 0 !important;
}
.tq-ddwn-tl.tq-options-btn {
  border-top-left-radius: 0 !important;
}
.tq-ddwn-tl.tq-options-btn .tq-options-btn-container {
  border-top-left-radius: 0 !important;
}
.tq-ddwn-tl:not(.tq-options-btn-reversed) .tq-button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 15px !important;
}
.tq-ddwn-tl.tq-options-btn-reversed .tq-dropdown-btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 15px !important;
}
.tq-ddwn-tl .tq-button-drop-down.tq-round {
  border-bottom-left-radius: 0 !important;
}
.tq-ddwn-tr.tq-options-btn {
  border-bottom-right-radius: 0 !important;
}
.tq-ddwn-tr.tq-options-btn .tq-options-btn-container {
  border-bottom-right-radius: 0 !important;
}
.tq-ddwn-tr.tq-options-btn-reversed .tq-button {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 15px !important;
}
.tq-ddwn-tr:not(.tq-options-btn-reversed) .tq-dropdown-btn {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 15px !important;
}
.tq-ddwn-tr .tq-button-drop-down.tq-round {
  border-top-right-radius: 0 !important;
}
.tq-button-message .tq-tooltip-alert {
  animation: slideFromBottom 0.3s ease;
}
@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.tq-breadcrumb-container .tq-breadcrumb-content {
  margin: 0;
  padding: 0;
}
.tq-breadcrumb-container .tq-breadcrumb-content li {
  display: inline-block;
  float: left;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.tq-breadcrumb-container .tq-breadcrumb-content li::after {
  display: inline-block;
  width: 20px;
  vertical-align: middle;
  text-align: center;
}
.tq-breadcrumb-container .tq-breadcrumb-content li:last-of-type::after {
  display: none;
}
.tq-breadcrumb-container .tq-breadcrumb-content li.tq-current {
  cursor: default;
}
.tq-breadcrumb-container .tq-breadcrumb-content li .fa-circle {
  vertical-align: middle;
  margin-top: -2px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li {
  position: relative;
  padding-right: 15px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li + li .tq-text-node {
  margin-left: 5px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li .tq-text-node {
  position: relative;
  vertical-align: top;
  width: 14px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li .tq-text-node:before {
  content: "...";
  position: absolute;
  left: 0;
  right: 10px;
  top: 0;
  bottom: 0;
  margin-top: -4px;
  width: 10px;
  text-align: center;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:after {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 12px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:first-child,
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:last-child {
  width: auto;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:first-child .tq-text-node,
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:last-child .tq-text-node {
  width: auto;
  text-indent: 0;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:first-child .tq-text-node:before,
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:last-child .tq-text-node:before {
  content: none;
}
/*.tq-app-header,
.tq-sub-header {
  .tq-breadcrumb-container {
    .tq-breadcrumb-content {
      li {
        color: fade(#fff, 60%);
        &::after {
          color: fade(#fff, 90%);
        }
        &.tq-current {
          color: fade(#fff, 90%) !important;
        }
        &:hover {
          color: @hyperlink-hover;
        }
      }
    }
  }
  &.tq-light {
    .tq-breadcrumb-container {
      .tq-breadcrumb-content {
        li {
          color: @hyperlink;
          &::after {
            color: lighten(@neutral-3, 15%);
          }
          &.tq-current {
            color: lighten(@neutral-3, 15%) !important;
          }
          &:hover {
            color: @hyperlink-hover;
          }
        }
      }
    }
  }
}
.tq-sub-header {
  .tq-breadcrumb-container {
    li {
      font-size: 12px;
    }
  }
}

.tq-panel {
  .tq-breadcrumb-container {
    .tq-breadcrumb-content {
      li {
        color: @hyperlink;
        &::after {
          color: lighten(@neutral-3, 15%);
        }
        &.tq-current {
          color: lighten(@neutral-3, 15%) !important;
        }
        &:hover {
          color: @hyperlink-hover;
        }
      }
    }
  }
  &.tq-accent {
    .tq-breadcrumb-container {
      .tq-breadcrumb-content {
        li {
          color: fade(#fff, 60%);
          &::after {
            color: fade(#fff, 90%);
          }
          &.tq-current {
            color: fade(#fff, 90%) !important;
          }
          &:hover {
            color: @hyperlink-hover;
          }
        }
      }
    }
  }
}*/
.tq-breadcrumb-container .tq-breadcrumb-content li {
  color: #003aaa;
}
.tq-breadcrumb-container .tq-breadcrumb-content li::after {
  content: "\e906";
  font-family: "tq-icons";
  color: #ffffff;
}
.tq-breadcrumb-container .tq-breadcrumb-content li.tq-current {
  color: #ffffff !important;
}
.tq-breadcrumb-container .tq-breadcrumb-content li:hover {
  color: #10C891;
}
.tq-breadcrumb-container .tq-breadcrumb-content li .fa-home {
  font-size: 13px !important;
}
.tq-breadcrumb-container .tq-breadcrumb-content li .fa-ellipsis-h {
  font-size: 10px !important;
}
.tq-breadcrumb-container .tq-breadcrumb-content li .fa-circle {
  font-size: 2px;
  letter-spacing: 2px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li .tq-text-node {
  font-size: 0pt;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li .tq-text-node:before {
  font-size: 16px;
}
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:first-child .tq-text-node,
.tq-breadcrumb-container.tq-ellipsis .tq-breadcrumb-content li:last-child .tq-text-node {
  text-indent: 0;
  font-size: 14px;
}
.tq-app-header .tq-breadcrumb-container .tq-breadcrumb-content li,
.tq-sub-header .tq-breadcrumb-container .tq-breadcrumb-content li {
  color: rgba(255, 255, 255, 0.6);
}
.tq-app-header .tq-breadcrumb-container .tq-breadcrumb-content li::after,
.tq-sub-header .tq-breadcrumb-container .tq-breadcrumb-content li::after {
  color: rgba(255, 255, 255, 0.9);
}
.tq-app-header .tq-breadcrumb-container .tq-breadcrumb-content li.tq-current,
.tq-sub-header .tq-breadcrumb-container .tq-breadcrumb-content li.tq-current {
  color: rgba(255, 255, 255, 0.9) !important;
}
.tq-app-header .tq-breadcrumb-container .tq-breadcrumb-content li:hover,
.tq-sub-header .tq-breadcrumb-container .tq-breadcrumb-content li:hover {
  color: #10C891;
}
.tq-app-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li,
.tq-sub-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li {
  color: #003aaa;
}
.tq-app-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li::after,
.tq-sub-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li::after {
  color: #ffffff;
}
.tq-app-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li.tq-current,
.tq-sub-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li.tq-current {
  color: #ffffff !important;
}
.tq-app-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li:hover,
.tq-sub-header.tq-light .tq-breadcrumb-container .tq-breadcrumb-content li:hover {
  color: #10C891;
}
.tq-sub-header .tq-breadcrumb-container li {
  font-size: 12px;
}
.tq-panel .tq-breadcrumb-container .tq-breadcrumb-content li {
  color: #003aaa;
}
.tq-panel .tq-breadcrumb-container .tq-breadcrumb-content li::after {
  color: #ffffff;
}
.tq-panel .tq-breadcrumb-container .tq-breadcrumb-content li.tq-current {
  color: #ffffff !important;
}
.tq-panel .tq-breadcrumb-container .tq-breadcrumb-content li:hover {
  color: #10C891;
}
.tq-panel.tq-accent .tq-breadcrumb-container .tq-breadcrumb-content li {
  color: rgba(255, 255, 255, 0.6);
}
.tq-panel.tq-accent .tq-breadcrumb-container .tq-breadcrumb-content li::after {
  color: rgba(255, 255, 255, 0.9);
}
.tq-panel.tq-accent .tq-breadcrumb-container .tq-breadcrumb-content li.tq-current {
  color: rgba(255, 255, 255, 0.9) !important;
}
.tq-panel.tq-accent .tq-breadcrumb-container .tq-breadcrumb-content li:hover {
  color: #10C891;
}
.tq-logo {
  height: 45px;
  /* (default size M) */
  width: auto;
  margin-left: 15px;
  vertical-align: middle;
  display: inline-block;
}
.tq-logo:after {
  content: none;
}
.tq-logo img {
  display: inline-block;
  height: 100%;
  width: auto;
  vertical-align: top;
}
.tq-logo.tq-logo-xs {
  height: 30px;
}
.tq-logo.tq-logo-s {
  height: 40px;
}
.tq-logo.tq-logo-l {
  height: 60px;
}
ul.tq-no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}
.tq-list-new {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.tq-list-new a:after {
  width: 0;
}
.tq-list-new li,
.tq-list-new .tq-li {
  display: block;
}
.tq-list-new li > .tq-item,
.tq-list-new .tq-li > .tq-item {
  position: relative;
  display: block;
  padding: 10px;
  transition: all 0.2s;
}
.tq-list-new li > .tq-item .tq-bullet,
.tq-list-new .tq-li > .tq-item .tq-bullet {
  position: absolute;
  line-height: 20px;
}
.tq-list-new li > .tq-item .tq-bullet[class*="fa-"],
.tq-list-new .tq-li > .tq-item .tq-bullet[class*="fa-"] {
  margin-top: 4px;
}
.tq-list-new li > .tq-item .tq-bullet + *,
.tq-list-new .tq-li > .tq-item .tq-bullet + * {
  padding-left: 18px;
  margin-left: 5px;
}
.tq-list-new li > .tq-item .tq-right-menu-items,
.tq-list-new .tq-li > .tq-item .tq-right-menu-items {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 36px;
  vertical-align: middle;
}
.tq-list-new li > .tq-item .tq-right-menu-items i,
.tq-list-new .tq-li > .tq-item .tq-right-menu-items i {
  padding: 0 5px;
  transition: all 0.3s;
}
.tq-list-new li > .tq-item .tq-right-menu-items .tq-button i,
.tq-list-new .tq-li > .tq-item .tq-right-menu-items .tq-button i,
.tq-list-new li > .tq-item .tq-right-menu-items .tq-btn i,
.tq-list-new .tq-li > .tq-item .tq-right-menu-items .tq-btn i {
  padding: 0;
}
.tq-list-new li > ul > li .tq-item,
.tq-list-new .tq-li > ul > li .tq-item,
.tq-list-new li > ul > .tq-li .tq-item,
.tq-list-new .tq-li > ul > .tq-li .tq-item {
  padding-left: 20px;
}
.tq-list-new li.tq-disabled > .tq-item,
.tq-list-new .tq-li.tq-disabled > .tq-item {
  opacity: 0.4;
}
.tq-list-new.tq-selectable li,
.tq-list-new.tq-selectable .tq-li {
  cursor: pointer;
  transition: all 0.5s;
}
.tq-list-new.tq-selectable li > .tq-item,
.tq-list-new.tq-selectable .tq-li > .tq-item {
  cursor: pointer;
}
.tq-list-new.tq-selectable li.tq-disabled,
.tq-list-new.tq-selectable .tq-li.tq-disabled {
  cursor: default;
}
.tq-list-new.tq-selectable li.tq-disabled > .tq-item,
.tq-list-new.tq-selectable .tq-li.tq-disabled > .tq-item {
  opacity: 0.4;
  cursor: default;
}
.tq-list-new.tq-steps li > .tq-item,
.tq-list-new.tq-steps .tq-li > .tq-item {
  padding-left: 30px;
}
.tq-list-new.tq-steps li > .tq-item:before,
.tq-list-new.tq-steps .tq-li > .tq-item:before {
  content: " ";
  position: absolute;
  top: 12px;
  left: 7px;
  width: 9px;
  height: 9px;
  text-align: center;
  vertical-align: middle;
  font-family: "tq-icons";
  font-size: 8px;
}
.tq-list-new.tq-steps li.tq-done > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-done > .tq-item:before {
  content: "\e91e";
  line-height: 9px;
}
.tq-list-new.tq-steps li.tq-current > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-current > .tq-item:before {
  content: "\e9ef";
}
.tq-list-new.tq-steps li.tq-todo > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-todo > .tq-item:before {
  content: "";
  line-height: 9px;
}
.tq-list-new.tq-steps li.tq-todo.tq-current > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-todo.tq-current > .tq-item:before {
  content: "\e9ef";
}
.tq-sidebar ul.tq-list-new li .tq-button.tq-no-background,
.tq-sidebar ul.tq-list-new .tq-li .tq-button.tq-no-background,
.tq-sidebar ul.tq-list-new li .tq-btn.tq-no-background,
.tq-sidebar ul.tq-list-new .tq-li .tq-btn.tq-no-background {
  padding: 4px;
  color: #fff;
}
.tq-sidebar .tq-small ul {
  width: 100%;
  text-align: center;
}
.tq-sidebar.tq-dark .tq-list-new .tq-parent.tq-expanded.tq-has-selected-child {
  transform: translateZ(0);
}
.tq-sidebar.tq-light .tq-parent.tq-expanded.tq-has-selected-child {
  transform: translateZ(0);
}
/* New List styles (17.01.2017) */
/*  -------------------------------------------------- */
ul.tq-no-bullets {
  text-decoration: none;
}
.tq-list-new li > ul li:last-child,
.tq-list-new .tq-li > ul li:last-child {
  border-bottom: 0;
}
.tq-list-new li.tq-disabled > .tq-item .tq-text-node,
.tq-list-new .tq-li.tq-disabled > .tq-item .tq-text-node {
  font-style: italic;
}
.tq-list-new.tq-borders > li,
.tq-list-new.tq-borders > .tq-li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.tq-list-new.tq-selectable li > .tq-item:hover,
.tq-list-new.tq-selectable .tq-li > .tq-item:hover {
  background: rgba(0, 0, 0, 0.05);
}
.tq-list-new.tq-selectable li.tq-current > .tq-item,
.tq-list-new.tq-selectable .tq-li.tq-current > .tq-item {
  background: rgba(0, 0, 0, 0);
}
.tq-list-new.tq-selectable li.tq-current > .tq-item:hover,
.tq-list-new.tq-selectable .tq-li.tq-current > .tq-item:hover {
  background: rgba(0, 0, 0, 0.05);
}
.tq-list-new.tq-selectable li.tq-current.tq-selected > .tq-item,
.tq-list-new.tq-selectable .tq-li.tq-current.tq-selected > .tq-item {
  background: #306ce0;
}
.tq-list-new.tq-selectable li.tq-selected > .tq-item,
.tq-list-new.tq-selectable .tq-li.tq-selected > .tq-item {
  background: #306ce0;
}
.tq-list-new.tq-selectable li.tq-selected > .tq-item:hover,
.tq-list-new.tq-selectable .tq-li.tq-selected > .tq-item:hover {
  background: #205ed7;
}
.tq-list-new.tq-selectable li.tq-disabled > .tq-item .tq-text-node,
.tq-list-new.tq-selectable .tq-li.tq-disabled > .tq-item .tq-text-node {
  font-style: italic;
}
.tq-list-new.tq-selectable li.tq-disabled:hover > .tq-item,
.tq-list-new.tq-selectable .tq-li.tq-disabled:hover > .tq-item {
  background: rgba(0, 0, 0, 0);
}
.tq-list-new.tq-steps li > .tq-item:before,
.tq-list-new.tq-steps .tq-li > .tq-item:before {
  border-radius: 9px;
  border: 2px solid;
  font-family: "tq-icons";
  font-size: 8px;
}
.tq-list-new.tq-steps li.tq-done > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-done > .tq-item:before {
  color: #07b24c;
  border-color: #07b24c;
}
.tq-list-new.tq-steps li.tq-current > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-current > .tq-item:before {
  font-size: 8px;
  line-height: 9px;
}
.tq-list-new.tq-steps li.tq-current.tq-done > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-current.tq-done > .tq-item:before,
.tq-list-new.tq-steps li.tq-current.tq-todo > .tq-item:before,
.tq-list-new.tq-steps .tq-li.tq-current.tq-todo > .tq-item:before {
  color: #10C891;
  border-color: #10C891;
}
.tq-sidebar ul.tq-list-new.tq-steps li > .tq-item:before,
.tq-sidebar ul.tq-list-new.tq-steps .tq-li > .tq-item:before {
  font-size: 8px;
}
.tq-sidebar ul.tq-list-new li .tq-button.tq-no-background,
.tq-sidebar ul.tq-list-new .tq-li .tq-button.tq-no-background,
.tq-sidebar ul.tq-list-new li .tq-btn.tq-no-background,
.tq-sidebar ul.tq-list-new .tq-li .tq-btn.tq-no-background {
  padding: 4px;
  font-size: 16px;
  color: inherit !important;
}
.tq-sidebar ul.tq-list-new li .tq-button.tq-no-background:hover,
.tq-sidebar ul.tq-list-new .tq-li .tq-button.tq-no-background:hover,
.tq-sidebar ul.tq-list-new li .tq-btn.tq-no-background:hover,
.tq-sidebar ul.tq-list-new .tq-li .tq-btn.tq-no-background:hover {
  color: #ffa96c !important;
}
.tq-sidebar .tq-small ul {
  width: 100%;
  text-align: center;
}
.tq-sidebar.tq-dark .tq-list-new .tq-parent.tq-expanded.tq-has-selected-child {
  background: rgba(245, 245, 245, 0.15) !important;
}
.tq-sidebar.tq-dark .tq-list-new .tq-selected > .tq-item .tq-tree-icon {
  color: inherit !important;
}
.tq-sidebar.tq-dark .tq-list-new .tq-selected > .tq-item .tq-expand-icon:before {
  color: inherit !important;
}
.tq-sidebar.tq-light .tq-parent.tq-expanded.tq-has-selected-child {
  background: rgba(0, 0, 0, 0.05) !important;
}
.tq-sidebar.tq-light .tq-selected > .tq-item .tq-tree-icon {
  color: #fff !important;
}
.tq-sidebar.tq-light .tq-selected > .tq-item .tq-expand-icon:before {
  color: #fff !important;
}
.tq-light.tq-opaque.tq-small ul.tq-list-new li:hover,
.tq-light.tq-opaque.tq-small ul.tq-list-new .tq-li:hover {
  color: #164194;
}
/* Banner component */
.tq-panel.tq-banner {
  position: relative;
  height: auto;
  min-height: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding: 15px;
  box-sizing: border-box;
  /* Content */
  /* Horizontal Alignment - default is left */
  /* Vertical Alignment */
  /* Banner predefined width */
  /* Banner predefined height */
}
.tq-panel.tq-banner .tq-banner-content {
  width: 80%;
}
.tq-panel.tq-banner.tq-banner-overlay:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.tq-panel.tq-banner.tq-banner-overlay .tq-banner-content {
  position: relative;
  z-index: 1;
}
.tq-panel.tq-banner.tq-text-background {
  padding: 15px 0;
}
.tq-panel.tq-banner.tq-text-background .tq-banner-content {
  padding: 15px;
}
.tq-panel.tq-banner .tq-panel-title {
  display: block;
  line-height: 1.2;
}
.tq-panel.tq-banner .tq-panel-title.tq-title-l {
  font-size: 30px;
}
.tq-panel.tq-banner .tq-panel-title.tq-title-xl {
  font-size: 40px;
}
.tq-panel.tq-banner .tq-banner-description {
  margin: 10px 0 15px;
}
.tq-panel.tq-banner.tq-clickable div {
  cursor: pointer;
}
.tq-panel.tq-banner.tq-text-right {
  justify-content: flex-end;
}
.tq-panel.tq-banner.tq-text-right .tq-banner-content {
  text-align: right;
  align-items: flex-end;
}
.tq-panel.tq-banner.tq-text-center .tq-banner-content {
  margin: 0 auto;
}
.tq-panel.tq-banner.tq-text-top {
  align-items: flex-start;
}
.tq-panel.tq-banner.tq-text-top .tq-banner-content {
  align-items: flex-start;
}
.tq-panel.tq-banner.tq-text-bottom {
  align-items: flex-end;
}
.tq-panel.tq-banner.tq-text-bottom .tq-banner-content {
  align-items: flex-end;
}
.tq-panel.tq-banner.tq-banner-w-120 {
  width: 120px;
}
.tq-panel.tq-banner.tq-banner-w-120 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-125 {
  width: 125px;
}
.tq-panel.tq-banner.tq-banner-w-125 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-160 {
  width: 160px;
}
.tq-panel.tq-banner.tq-banner-w-160 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-180 {
  width: 180px;
}
.tq-panel.tq-banner.tq-banner-w-180 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-234 {
  width: 234px;
}
.tq-panel.tq-banner.tq-banner-w-234 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-240 {
  width: 240px;
}
.tq-panel.tq-banner.tq-banner-w-240 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-250 {
  width: 250px;
}
.tq-panel.tq-banner.tq-banner-w-250 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-w-300 {
  width: 300px;
}
.tq-panel.tq-banner.tq-banner-w-336 {
  width: 336px;
}
.tq-panel.tq-banner.tq-banner-w-468 {
  width: 468px;
}
.tq-panel.tq-banner.tq-banner-w-728 {
  width: 728px;
}
.tq-panel.tq-banner.tq-banner-h-60 {
  min-height: 60px;
}
.tq-panel.tq-banner.tq-banner-h-80 {
  min-height: 80px;
}
.tq-panel.tq-banner.tq-banner-h-90 {
  min-height: 90px;
}
.tq-panel.tq-banner.tq-banner-h-125 {
  min-height: 125px;
}
.tq-panel.tq-banner.tq-banner-h-150 {
  min-height: 150px;
}
.tq-panel.tq-banner.tq-banner-h-250 {
  min-height: 250px;
}
.tq-panel.tq-banner.tq-banner-h-280 {
  min-height: 280px;
}
.tq-panel.tq-banner.tq-banner-h-400 {
  min-height: 400px;
}
.tq-panel.tq-banner.tq-banner-h-400 .tq-banner-content {
  width: 100%;
}
.tq-panel.tq-banner.tq-banner-h-600 {
  min-height: 600px;
}
.tq-panel.tq-banner.tq-banner-h-600 .tq-banner-content {
  width: 100%;
}
/* Banner component */
@media (max-width: 1024px) {
  .tq-panel.tq-banner .tq-banner-content {
    width: 80%;
  }
  .tq-panel.tq-banner .tq-panel-title.tq-title-l {
    font-size: 25px;
  }
  .tq-panel.tq-banner .tq-panel-title.tq-title-xl {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .tq-panel.tq-banner .tq-banner-content {
    width: 100%;
  }
  .tq-panel.tq-banner .tq-panel-title {
    font-size: 16px;
  }
  .tq-panel.tq-banner .tq-panel-title.tq-title-l {
    font-size: 20px;
  }
  .tq-panel.tq-banner .tq-panel-title.tq-title-xl {
    font-size: 25px;
  }
  .tq-panel.tq-banner .tq-banner-description {
    font-size: 95%;
    line-height: 1.3;
  }
}
@media (max-width: 480px) {
  .tq-panel.tq-banner {
    min-height: auto !important;
  }
}
/* Banner component */
.tq-panel.tq-banner {
  /* Content */
}
.tq-panel.tq-banner .tq-banner-content {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.tq-panel.tq-banner.tq-dark-text .tq-banner-content {
  color: #939393;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
}
.tq-panel.tq-banner.tq-dark-text.tq-banner-overlay:after {
  background: rgba(255, 255, 255, 0.6);
}
.tq-panel.tq-banner.tq-dark-text.tq-text-background .tq-banner-content {
  background: rgba(255, 255, 255, 0.6);
}
.tq-panel.tq-banner.tq-banner-overlay:after {
  background: rgba(168, 168, 168, 0.7);
}
.tq-panel.tq-banner.tq-text-background .tq-banner-content {
  background: rgba(22, 65, 148, 0.7);
  text-shadow: none;
}
.tq-panel {
  margin-bottom: 20px;
  position: relative;
  transition: all 0.4s ease-in-out;
  line-height: 19px;
}
.tq-panel.tq-panel-no-margin {
  margin-bottom: 0 !important;
}
.tq-panel.tq-no-border {
  border: 0 none;
  box-shadow: none;
}
.tq-panel-xs {
  height: 120px;
}
.tq-panel-s {
  height: 200px;
}
.tq-panel-m {
  height: 300px;
}
.tq-panel {
  background: #FFF;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  color: #164194;
  border: none !important;
}
.tq-panel.tq-no-border {
  border: 0 none;
  box-shadow: none;
}
.tq-panel.tq-accent {
  color: #FFF;
  background: rgba(219, 219, 219, 0.9);
}
.tq-panel.tq-accent .tq-button.tq-primary,
.tq-panel.tq-accent .tq-btn.tq-primary {
  border-color: #fff;
}
.tq-panel.tq-accent .tq-button.tq-no-background,
.tq-panel.tq-accent .tq-btn.tq-no-background {
  color: #ffa96c !important;
  background: transparent;
}
.tq-panel.tq-accent .tq-button.tq-no-background:hover,
.tq-panel.tq-accent .tq-btn.tq-no-background:hover {
  color: #fff !important;
}
.tq-panel.tq-accent .tq-red {
  color: #f1a0a3 !important;
}
.tq-panel.tq-card.tq-accent {
  background: rgba(219, 219, 219, 0.9);
}
.tq-input-container.tq-select .tq-drop-down-selected {
  position: relative;
  min-height: 27px;
  cursor: pointer;
  padding: 3px 28px 3px 10px;
  outline: none;
  /*z-index: 90;*/
  box-sizing: border-box;
  transition: color 0.3s ease-in-out;
}
.tq-input-container.tq-select .tq-drop-down-selected.tq-active {
  outline: none;
}
.tq-input-container.tq-select .tq-drop-down-selected .tq-feedback-container {
  top: 0;
  right: 0;
}
.tq-input-container.tq-select.tq-overflow .tq-drop-down-selected {
  position: relative;
}
.tq-input-container.tq-select.tq-overflow .tq-drop-down-selected .tq-selected-item {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-y: auto;
  white-space: nowrap;
  max-height: 120px;
  width: 100%;
}
.tq-input-container.tq-select-image {
  width: auto !important;
}
.tq-input-container.tq-select-image .tq-drop-down-selected {
  width: auto;
  padding-right: 19px;
}
.tq-input-container.tq-select-image .tq-drop-down-selected:hover {
  cursor: pointer;
}
.tq-input-container.tq-select-image .tq-select-counter:before {
  content: '(';
  display: inline-block;
}
.tq-input-container.tq-select-image .tq-select-counter:after {
  content: ')';
  display: inline-block;
}
.tq-drop-down-options {
  padding: 0;
  margin: 0;
  opacity: 1;
  height: auto;
  overflow-y: auto;
  width: auto;
  position: relative;
  /*  &.tq-with-checkbox {
    overflow-y: hidden !important;
    .tq-multiselect-search {
      position: absolute;
      width: 100%;
    }
    .tq-checkbox-group {
      margin-top: 25px;
      overflow-y: auto;
      height: 100%;
      max-height: 200px;
      padding-top: 5px;
    }
  }*/
  /*  &.tq-image-options {
    li{
      i{
        min-width: 16px;
      }
    }
  }*/
  /*  &.tq-checkbox-options{
    li{
      .tq-form-element{
        .tq-input-container{
          &.tq-checkbox-group{
            .tq-checkbox-option{
              line-height: inherit;
              .tq-text-node{
                color: inherit;
              }
            }
          }
        }
      }
    }
  }*/
}
.tq-drop-down-options li {
  border-bottom: 1px solid #fff !important;
  list-style: none;
  display: flex;
  min-height: 17px;
  align-items: flex-start;
  transition: background 0.1s ease-in-out;
  padding: 3px 10px;
}
.tq-drop-down-options li:hover,
.tq-drop-down-options li .tq-keyboard-nav {
  cursor: pointer;
}
.tq-drop-down-options li:last-child {
  border-bottom: none !important;
}
.tq-drop-down-options li .tq-text-node.tq-no-margin {
  margin: 0 !important;
}
.tq-drop-down-options li img {
  margin-left: 10px;
  height: 17px;
  vertical-align: middle;
}
.tq-drop-down-options li .tq-form-element {
  width: 100%;
}
.tq-drop-down-options li .tq-checkbox {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  margin: 1px 2px 0 0;
}
.tq-drop-down-options li .tq-checkbox input[type="checkbox"] {
  opacity: 0;
  display: none;
}
.tq-drop-down-options li .tq-checkbox:hover {
  cursor: pointer;
}
.tq-drop-down-options li.tq-selected .tq-checkbox:before {
  display: block;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  padding-left: 1px;
}
.tq-drop-down-options li.tq-selected:hover {
  cursor: pointer;
}
.tq-drop-down-options li.tq-disabled {
  cursor: not-allowed !important;
}
.tq-drop-down-options li.tq-disabled:hover .tq-checkbox,
.tq-drop-down-options li.tq-disabled:hover .tq-checkbox input {
  cursor: not-allowed !important;
}
.tq-drop-down-options li.tq-multiselect-search {
  display: block;
  padding: 0;
}
.tq-input-container.tq-select .tq-drop-down-selected {
  border: 1px solid #EEEEEE;
  background: #fff;
  color: #EF7B52;
  font-size: 12px;
  text-align: left;
}
.tq-input-container.tq-select .tq-drop-down-selected:hover {
  border-color: #d5d5d5;
}
.tq-input-container.tq-select .tq-drop-down-selected:focus {
  border-color: #164194;
  background: #fff;
}
.tq-input-container.tq-select .tq-drop-down-selected.tq-active {
  border: 1px solid #164194;
}
.tq-input-container.tq-select.tq-has-error .tq-drop-down-selected {
  border-color: #d91f26;
  box-shadow: 0 0 5px rgba(217, 31, 38, 0.6);
}
.tq-input-container.tq-select-image .tq-drop-down-selected {
  transition: color 0.5s ease-in-out;
  color: #164194;
  background: none;
}
.tq-input-container.tq-select-image .tq-drop-down-selected:hover {
  color: #10C891;
}
.tq-input-container.tq-select-image .tq-select-counter {
  font-size: 9px;
}
.tq-drop-down-options li:hover,
.tq-drop-down-options li.tq-keyboard-nav {
  background: rgba(238, 238, 238, 0.5);
  cursor: pointer;
}
.tq-drop-down-options li:hover .tq-checkbox,
.tq-drop-down-options li.tq-keyboard-nav .tq-checkbox {
  border: 1px solid #164194;
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
}
.tq-drop-down-options li.tq-current {
  background: #10C891;
  color: #fff;
}
.tq-drop-down-options li.tq-current:hover,
.tq-drop-down-options li.tq-current.tq-keyboard-nav {
  background: #1eedaf;
}
.tq-drop-down-options li .tq-text-node {
  line-height: 17px;
}
.tq-drop-down-options li img {
  line-height: 17px;
}
.tq-drop-down-options li i {
  margin-top: 1px;
}
.tq-drop-down-options li .tq-checkbox {
  border: 1px solid #EEEEEE;
  overflow: hidden;
  background: #fff;
}
.tq-drop-down-options li.tq-selected .tq-checkbox {
  border: 1px solid #164194;
}
.tq-drop-down-options li.tq-selected .tq-checkbox:before {
  content: "\e91e";
  font-family: 'tq-icons';
  font-size: 11px;
  line-height: 15px;
  color: #164194;
}
.tq-drop-down-options li.tq-selected:hover .tq-checkbox {
  border: 1px solid #164194;
}
.tq-drop-down-options li.tq-selected:hover .tq-checkbox:before {
  color: #164194;
}
.tq-drop-down-options li.tq-disabled {
  background: rgba(200, 200, 200, 0.2);
  color: rgba(219, 219, 219, 0.6);
}
.tq-drop-down-options li.tq-disabled .tq-checkbox {
  background: transparent !important;
}
.tq-drop-down-options li.tq-disabled:hover .tq-checkbox,
.tq-drop-down-options li.tq-disabled:hover .tq-checkbox input {
  cursor: not-allowed !important;
}
.tq-drop-down-options li.tq-disabled:hover .tq-checkbox {
  border: 1px solid #EEEEEE;
  box-shadow: none;
}
.tq-drop-down-options li:focus {
  background: rgba(238, 238, 238, 0.5);
}
.tq-drop-down-options li:focus.tq-current {
  background: #1eedaf;
}
.tq-drop-down-options {
  border: 1px solid #EEEEEE;
  font-size: 12px;
  background: #fff;
  /*  .tq-checkbox-group-container {
    .tq-checkbox-group {
      .tq-text-static {
        &.tq-current {
          background-color: @secondary-color-1;
          color: #FFF;
          &:hover {
            background: lighten(@secondary-color-1, 10%);
          }
        }
        &:hover {
          background-color: fade(@neutral-4, 50%);
        }
      }
    }
  }*/
}
